import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "./Home.css";
import Opener from "../../components/Sections/Opener";
import Columns from "../../components/Sections/Columns";
import SlideshowColumns from "../../components/Sections/SlideshowColumns";
// import Logos from "../../components/Sections/Logos";
import { Button } from "antd";
import { Error } from "../../components/Messages/messages";
import { isAuthenticated } from "../../components/Auth/auth";
// import { ReactComponent as Threads } from "../../assets/threads.svg";
import Analytics from "../../analytics";
import Highlights from "../../components/Sections/Highlights";

const Home = (props) => {
  const user = isAuthenticated();
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.blur();
  }, []);

  const handleChange = (e) => {
    setUserName(e.target.value.toLowerCase().replace(/\s/g, ""));
  };

  const handleClick = () => {
    props.history.push(`/signup`);
  };

  function handleClickClassRemoval() {
    document.body.classList.remove("cta-clicked");
  }

  const checkUserName = async () => {
    if (!userName) {
      return Error("Please enter a handle");
    }
    Analytics.sendEvent("get_started", "click", userName.toLowerCase());
    await axios
      .get(`/api/users/check/${userName}`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          props.history.push(`/signup/${userName}`);
          document.location.reload();
        } else {
          Error("Handle taken, please try another.");
        }
      })
      .catch((err) => {
        setLoading(false);
        Error(err?.response?.data?.errorMessage);
      });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    // window.scrollTo(0, 0);
    setLoading(true);
    checkUserName();
  };

  const features = [
    {
      title: "@Ghfloat",
      image:
      "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1710280031/Tapfolio/llpsak7qansr6n46kkar.jpg",
      link: "https://tapfol.io/ghfloat",
      buttonText: "Preview",
    },
    {
      title: "@WhatsupSCV",
      image:
      "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1683660414/Tapfolio/ymjhgrdp3olrgcxbq6yc.jpg",
      link: "https://tapfol.io/whatsupscv",
      buttonText: "Preview",
    },
    {
      title: "@SunamiFab",
      image:
      "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1698803272/Tapfolio/mbfmeaqnlt3olfkk0dwm.jpg",
      link: "https://tapfol.io/sunamifab",
      buttonText: "Preview",
    },
    {
      title: "@Dane",
      image:
      "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1686010006/Tapfolio/baiykdf7s1zdvglaf9ui.jpg",
      link: "https://tapfol.io/dane",
      buttonText: "Preview",
    },
    {
      title: "@Jasonogdon",
      image:
        "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1682563567/Tapfolio/o9tlz50os0faosli5xiv.jpg",
      link: "https://tapfol.io/jasonogdon",
      buttonText: "Preview",
    },
    {
      title: "@Teddi.prior",
      image:
        "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1689609717/Tapfolio/p0ks26okedvyuwoxkjp4.jpg",
      link: "https://tapfol.io/teddi.prior",
      buttonText: "Preview",
    },
    {
      title: "@Whiskeycreekcabin",
      image:
      "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1682120257/Tapfolio/xfi7hhftiw7sdtvuqxmm.jpg",
      link: "https://tapfol.io/whiskeycreekcabin",
      buttonText: "Preview",
    },
    {
      title: "@Sara_de",
      image:
        "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1682435277/Tapfolio/kuh5pna6qonagb6kudgv.jpg",
      link: "https://tapfol.io/sara_de",
      buttonText: "Preview",
    },
  ];

  const branding = [
    {
      title: "Brand matching",
      image:
        "https://images.unsplash.com/photo-1494253109108-2e30c049369b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=100",
      text: "Tapfolio offers branded links that match your color scheme so that you can make the most out of the buttons you place in your bio or shareable links.",
      // link: "/signup",
      // buttonText: "Get Yours",
    },
    {
      title: "Seamless editing",
      image:
        "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1696551159/linkedin-sales-solutions-zn2aUVfbUrk-unsplash-1_dpwchh.jpg",
      text: "You can customize better-looking links that can be added to your pages that have your brand colors and share styles and imagery with embedded links that match your current logos.",
      // link: "/signup",
      // buttonText: "Get Yours",
    },
    {
      title: "Blend in",
      image:
        "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1696538638/le-buzz-studio-aM9sj98dI2A-unsplash_mgavkn.jpg",
      text: "Link buttons that fit your branding will create a more professional feel on your page and help you establish a better brand theme with your content focused marketing.",
      // link: "/signup",
      // buttonText: "Get Yours",
    },
  ];

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      // Check if the scroll position is more than the window's inner height
      if (window.scrollY > 0) {
        document.body.classList.add("has-scrolled");
      } else {
        document.body.classList.remove("has-scrolled");
      }
    };

    // Add the 'signup-body' class initially
    document.body.classList.add("home-body");
    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      document.body.classList.remove("home-body");
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (user) {
      props.history.push(`/dashboard`);
    }
    props.updateNavbar(false);
  }, [props, user]);

  return (
    <div className="home">
      <div id="intro" className="top-section">
        <section>
          <div className="two-col">
    
            <div className="right">
              <h1>Boost your Brand<span>in 2 Minutes</span></h1>

              <p>
                Web. Products. Socials.
              </p>

              <div
                className="form-meta desktop"
                style={{
                  display: "flex",
                }}
              >
                {/* DESKTOP ONLY */}
                <div className="form-text" onClick={handleClickClassRemoval}>
                  <span className="placeholder">tapfol.io/</span>
                  <input
                    id="usernameInfo"
                    name="name"
                    value={userName}
                    required
                    type="text"
                    ref={inputRef}
                    autoFocus={false}
                    className="form-control main-field"
                    placeholder=""
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        submitHandler(event);
                      }
                    }}
                  />
                </div>

                <Button
                  className="form-button main-cta-btn"
                  onClick={submitHandler}
                  type="primary"
                  disabled={loading}
                  style={{ width: "200px", height: "50px" }}
                >
                  Get Yours
                </Button>
              </div>
            </div>

            {/* MOBILE ONLY */}
            <div
              className="form-meta mobile"
              style={{
                display: "flex",
              }}
            >
              <div className="form-text" onClick={handleClickClassRemoval}>
                <span className="placeholder">tapfol.io/</span>
                <input
                  id="usernameMobile"
                  name="name"
                  value={userName}
                  required
                  autoFocus={false}
                  ref={inputRef}
                  type="text"
                  className="form-control"
                  placeholder=""
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      submitHandler(event);
                    }
                  }}
                />
              </div>

              <Button
                className="form-button"
                onClick={submitHandler}
                type="primary"
                style={{ width: "200px", height: "50px" }}
              >
                Get Yours
              </Button>
            </div>

            <div className="worm-meta">
              <div className="worm bounce" />
            </div>

            <img width="1400" height="850" className="hero-bg" alt="Tapfolio Examples" src="https://res.cloudinary.com/dhbf9ny7k/image/upload/v1701195055/TF-Banner-Border_1_kydm0q.jpg" />
          </div>
        </section>
      </div>

      <a id="highlights"/>
      <Highlights />

      <Opener
        image={{
          src: "https://images.unsplash.com/photo-1496131567027-f594499b5c8b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1332&q=100",
          alt: "A better link in bio",
        }}
        title="A better link in bio"
        text="<p>Elevate your brand by showcasing images and visuals alongside your webpages, products, and social media platforms.</p>"
      />
      <a id="community"/>

      <SlideshowColumns title="Community" items={features} columns={4} />
      <a id="analytics-opener"/>
      <Opener
        image={{
          src: "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1696551159/visax-eSxq_4Lujq8-unsplash_jzjvef.jpg?v=1",
          alt: "Analytics and data for your link in bio",
        }}
        title="Analytics for your bio"
        text="<p>Choose time ranges from 1 day, one month, or one year, and check referral links where your Tapfolio is posted to see which platforms lead to the best conversion for your presentations. You can track engagement on each slide of your Tapfolio through the analytics screen and measure retention in swipes through your content. 
        </p>"
      />

      <Opener
        image={{
          src: "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1696538638/robert-horvick-1R4uPYipCFM-unsplash_qphft1.jpg",
          alt: "Link buttons that match your branding",
        }}
        title="Link buttons that match your branding"
        text="<p>We offer branded links that match your color scheme so that you can make the most out of the buttons you place in your bio or shareable links. You can customize better-looking links that can be added to your pages that have your brand colors and share styles and imagery with embedded links that match your current logos.</p>"
      />

      <Columns items={branding} columns={3} />

      <section id="pricing" className="bottom offset-anchor">
        <h3>Free Plan</h3>
        <h4>Improve your link in bio at no cost</h4>
        <p>No catches.</p>
        <div className="cols">
          <div className="column pricingCard mt-4 gradient-border">
            <ul>

              <li>10 Tap Slides</li>
              <li>Custom Colors</li>
              <li>Custom Links</li>
              <li>Custom Text</li>
              <li>Social Links</li>
              <li>Unique URL</li>
              <li>Font Options</li>
              <li>Button Options</li>
              <li>Instant Analytics</li>
            </ul>
            <button
              className="form-button bottom-cta text-center"
              type="button"
              onClick={handleClick}
              style={{ width: "200px", height: "50px" }}
            >
              Sign up free
            </button>
          </div>

        </div>

        <p className="mt-5">
          Tapfolio offers a dynamic solution for brand owners and marketers, revolutionizing the way you design content for link-in-bio experiences. Make your brand shine with an engaging and easily navigable presentation that enhances your brand image and deepens comprehension of your offerings. With Tapfolio, you gain access to invaluable data insights, uncovering key details about your audience's interests. This information is crucial for tailoring future content more effectively. Experience the transformation of your link-in-bio and elevate your online presence by joining Tapfolio today. Discover the untapped potential of your brand's online engagement
        </p>
      </section>
    </div>
  );
};

export default Home;
