import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './GallaryCard.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import { ReactComponent as EtsySvg } from "../../assets/etsy.svg";
// import { ReactComponent as TikTokSvg } from "../../assets/tiktok.svg";
// import {
//   InstagramFilled,
//   GoogleCircleFilled,
//   GlobalOutlined,
//   FacebookFilled,
//   TwitterCircleFilled,
//   YoutubeFilled,
//   RedditCircleFilled,
//   AmazonCircleFilled,
//   LinkedinFilled,
// } from "@ant-design/icons";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
  A11y,
} from 'swiper';
import { isAuthenticated } from '../Auth/auth';
import tinycolor from 'tinycolor2';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { onClickLink, onClickSwipe } from '../../utility';

export const GallaryCard = ({ data, slideIndex }) => {
  const [swiper, setSwiper] = useState(null);
  const [eventsReady, setEventsReady] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (
      !eventsReady &&
      swiper &&
      data &&
      data.pictures &&
      data.pictures.length > 0
    ) {
      setEventsReady(true);
    }
  }, [swiper, data]);

  const logSwipe = async (e) => {
    try {
      if (eventsReady) {
        const currentIndex = e.activeIndex;
        if (currentIndex < activeIndex) {
          await onClickSwipe(
            'SWIPE_PREV',
            data.pictures[activeIndex].picture.url,
          );
        } else if (currentIndex > activeIndex) {
          await onClickSwipe(
            'SWIPE_NEXT',
            data.pictures[activeIndex].picture.url,
          );
        }
        setActiveIndex(currentIndex);
      }
    } catch (error) {
      console.error(e.message);
    }
  };

  const slideTo = () => {
    if (swiper) swiper.slideTo(slideIndex);
  };

  const color = tinycolor(data?.themeColor);
  const rgb = color.toRgb();

  // Specfic color check
  if (
    (rgb.r === 238 && rgb.g === 238 && rgb.b === 238 && color.isLight()) ||
    data?.isTextLight
  ) {
    // This check all colors
    // if (color.isLight()) {

    // add a class to the body element
    document.body.classList.add('light-theme');
  }

  useEffect(() => {
    slideTo(slideIndex);

    return () => {};
  }, [slideIndex]);

  useEffect(() => {
    if (data?.themeFont) {
      const fontLink = document.createElement('link');
      fontLink.href = `https://fonts.googleapis.com/css?family=${data?.themeFont}`;
      fontLink.rel = 'stylesheet';
      document.head.appendChild(fontLink);
    }
  }, [data?.themeFont]);

  useEffect(() => {
    if (data?.themeFont) {
      // Transform themeFont: make it lowercase and replace spaces with hyphens
      const formattedFontClass = data.themeFont
        .toLowerCase()
        .replace(/\s+/g, '-');

      // Add the class to all .main-link elements
      const mainLinks = document.querySelectorAll('.main-link');
      mainLinks.forEach((link) => {
        link.classList.add(formattedFontClass); // Use the formatted font class
      });
    }
  }, [data?.themeFont]);

  const renderTitleLayout = (pic) => {
    switch (data.buttonType) {
      case 'mario':
        // Return a special title layout
        return (
          <>
            {pic?.link && pic?.title ? (
              <a
                className="main-link title mario"
                target="_blank"
                rel="noreferrer"
                href={pic?.link}
                onClick={() =>
                  onClickLink(pic?.link, "CLICK_IMAGE", pic?.picture?.url)
                }
              >
                <span className="button-outline">
                  <span className="button-shadow">
                    <span
                      className="button-inside"
                      style={{
                        background: `rgba(${data?.themeColor?.r}, ${data?.themeColor?.g}, ${data?.themeColor?.b}, ${data?.themeColor?.a})`,
                        fontFamily: data?.themeFont,
                      }}
                    >
                      {pic.title}
                    </span>
                  </span>
                </span>
              </a>
            ) : // Has title and no link.
            pic?.title && !pic?.link ? (
              <span
                className="main-link no-link title mario"
                target="_blank"
                rel="noreferrer"
              >
                <span className="button-outline">
                  <span className="button-shadow">
                    <span
                      className="button-inside"
                      style={{
                        background: `rgba(${data?.themeColor?.r}, ${data?.themeColor?.g}, ${data?.themeColor?.b}, ${data?.themeColor?.a})`,
                        fontFamily: data?.themeFont,
                      }}
                    >
                      {pic.title}
                    </span>
                  </span>
                </span>
              </span>
            ) : (
              ''
            )}
          </>
        );
      // Add more cases for different button types
      default:
        // Return default title layout
        return (
          <>
            {pic?.link && pic?.title ? (
              <a
                className={`main-link title ${data?.buttonType}`}
                target="_blank"
                rel="noreferrer"
                style={{
                  background: data?.themeColor
                    ? `rgba(${data?.themeColor?.r}, ${data?.themeColor?.g}, ${data?.themeColor?.b}, ${data?.themeColor?.a})`
                    : 'rgba(0, 0, 0, 0.5)',
                  fontFamily: `${data?.themeFont}`,
                }}
                href={pic?.link}
                onClick={() =>
                  onClickLink(pic?.link, 'CLICK_IMAGE', pic?.picture?.url)
                }
              >
                <h2 className="inner-title">{pic?.title}</h2>
              </a>
            ) : // Has title and no link.
            pic?.title && !pic?.link ? (
              <span
                className={`main-link title no-link ${data?.buttonType}`}
                style={{
                  background: data?.themeColor
                    ? `rgba(${data?.themeColor?.r}, ${data?.themeColor?.g}, ${data?.themeColor?.b}, ${data?.themeColor?.a})`
                    : 'rgba(0, 0, 0, 0.5)',
                  fontFamily: `${data?.themeFont}`,
                }}
              >
                <h2 className="inner-title">{pic?.title}</h2>
              </span>
            ) : (
              ''
            )}
          </>
        );
    }
  };

  return (
    <div className="GallaryCard">
      <Swiper
        loop={false}
        cssMode={true}
        navigation={true}
        initialSlide={0}
        lazy={true}
        preloadImages={false}
        pagination={{
          type: 'progressbar',
        }}
        mousewheel={true}
        autoplay={false}
        onSwiper={setSwiper}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay, A11y]}
        className="mySwiper"
        onRealIndexChange={logSwipe}
      >
        {data?.pictures &&
          data.pictures.length > 0 &&
          data.pictures.map((pic, index) => {
            return (
              <div key={`SwiperSlide_${pic._id}`}>
                <SwiperSlide key={index}>
                  {/* No URL or title image */}
                  {pic?.link && pic?.title === '' ? (
                    <a href={pic?.link} target="_blank" rel="noreferrer">
                      <LazyLoadImage
                        effect="blur"
                        src={pic?.picture?.url}
                        alt={pic?.title}
                        className="w-100"
                        style={
                          pic?.cover === 'true'
                            ? { objectFit: 'cover' }
                            : { objectFit: 'contain' }
                        }
                      />
                    </a>
                  ) : (
                    <LazyLoadImage
                      src={pic?.picture?.url}
                      effect="blur"
                      alt={pic?.title}
                      className="w-100"
                      style={
                        pic?.cover === 'true'
                          ? { objectFit: 'cover' }
                          : { objectFit: 'contain' }
                      }
                    />
                  )}

                  <div className="info-section">
                    {renderTitleLayout(pic)}

                    {pic?.text === '' ? (
                      ''
                    ) : (
                      <a
                        onClick={() =>
                          onClickLink(
                            pic?.link,
                            'CLICK_IMAGE',
                            pic?.picture?.url,
                          )
                        }
                        href={pic?.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div
                          className="desc"
                          style={{
                            background: data?.themeColor
                              ? `rgba(${data?.themeColor?.r}, ${data?.themeColor?.g}, ${data?.themeColor?.b}, ${data?.themeColor?.a})`
                              : 'rgba(0, 0, 0, 0.5)',
                            fontFamily: `${data?.themeFont}`,
                          }}
                        >
                          {pic?.text}
                        </div>
                      </a>
                    )}
                  </div>
                  <span
                    className="bg-color"
                    style={{
                      background: data?.themeColor
                        ? `rgba(${data?.themeColor?.r}, ${data?.themeColor?.g}, ${data?.themeColor?.b}, ${data?.themeColor?.a})`
                        : 'rgba(0, 0, 0, 0.5)',
                    }}
                  ></span>
                </SwiperSlide>
              </div>
            );
          })}
      </Swiper>
      {
        !isAuthenticated() && ''
        // <div className="buy-section my-2">
        //   <div className="buttons">
        //     <div>
        //       <Link
        //         className="btn btn-dark"
        //         to={"/checkout/" + data?.username + "/" + 3}
        //       >
        //         Start for free
        //       </Link>
        //     </div>
        //     <div>
        //       <Link
        //         className="btn btn-dark"
        //         to={"/checkout/" + data?.username + "/" + 10}
        //       >
        //         Buy for $2.99
        //       </Link>
        //     </div>
        //     <div>
        //       <Link
        //         className="btn btn-dark"
        //         to={"/checkout/" + data?.username + "/" + 20}
        //       >
        //         Buy for $9.99
        //       </Link>
        //     </div>
        //   </div>
        // </div>
      }
    </div>
  );
};
