import React, { useEffect, useCallback } from 'react';
import WebFont from 'webfontloader';
import './FontSelector.css';

const FontSelector = ({ onFontSelect, themeFont }) => {
  const fonts = [
    "Lato", "Salsa", "Kanit", 
    "Oswald", "Anton", "Lobster", "Neuton", "Pacifico",
    "Raleway", "Bebas Neue",
  ];

  useEffect(() => {
    WebFont.load({
      google: {
        families: fonts
      }
    });
  }, []);

  const handleFontChange = useCallback((fontName) => {
    onFontSelect(fontName);
  }, [onFontSelect]);

  const isActive = (fontName) => {
    return fontName === themeFont;
  };

  return (
    <div className='font-selector inner-slider'>
      <div className='font-options'>
        {fonts.map((font, index) => (
          <div 
            key={index}  
            className={`font-option ${isActive(font) ? 'active' : ''}`}
            style={{ fontFamily: font }}
            onClick={() => handleFontChange(font)}
          >
            {font}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FontSelector;
