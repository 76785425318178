import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './ProductCard.css';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { EffectCards, Navigation, Keyboard, Pagination } from 'swiper';
import { Link } from 'react-router-dom';
import { DeleteFilled } from '@ant-design/icons';
import axios from 'axios';
import { Error, Success } from '../Messages/messages';
import { Popconfirm } from 'antd';
import UpdateText from '../Dashboard/UploadForm/UpdateText';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const ProductCard = ({
  data,
  qty,
  noBuyButton,
  update,
  dashboard,
  finalColor,
  themeFont,
  buttonType
}) => {
  const [loading, setLoading] = useState(false);

  const renderButton = (pic, index) => {
    switch (buttonType) {
      case 'mario':
        // Return mario button JSX
        return (
          <button 
          className={`btn text-white example-btn mario ${themeFont.toLowerCase()
            .replace(/\s+/g, '-')} ${!pic.title ? 'no-title' : ''}`}
          >
            <span className="button-outline">    
                <span className="button-shadow">
                  <span className="button-inside"
                  style={{
                    background: `rgba(${finalColor?.r}, ${finalColor?.g}, ${finalColor?.b}, ${finalColor?.a})`,
                    fontFamily: themeFont
                  }}>
                    {pic.title}
                  </span>
                </span>
              </span>
          </button>
        );
      // Add more cases for different button types
      default:
        // Return default button JSX
        return (
          <button
            className={`btn text-white example-btn ${buttonType} ${!pic.title ? 'no-title' : ''}`}
            style={{
              background: `rgba(${finalColor?.r}, ${finalColor?.g}, ${finalColor?.b}, ${finalColor?.a})`, 
              fontFamily: `${themeFont}`
            }}
          >
            {pic.title}
          </button>
        );
    }
  };

  const confirm = (id) => {
    deleteHandler(id);
  };
  const cancel = (e) => {
    Error('Cancelled!');
  };

  const deleteHandler = async (id) => {
    if(loading) {
      return
    }
    
    setLoading(true);
    await axios
      .delete(`/api/pictures/delete/${id}`, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(async (res) => {
        setLoading(false);
        if (res.status === 200) {
          Success(res.data.successMessage);
          document.location.reload();
          update();
        } else {
          Error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className='ProductCard'>
      <Swiper
        loop={dashboard ? false : true}
        shortSwipes
        thumbs={true}
        lazy={true}
        preloadImages={false}
        pagination={true}
        effect={'cards'}
        navigation={dashboard ? true : false}
        grabCursor={true}
        keyboard={true}
        modules={[EffectCards, Navigation, Keyboard, Pagination]}
        className='mySwiper'
        
      >
        {data?.pictures &&
          data.pictures.length > 0 &&
          data.pictures.map((pic, index) => {
            return (
              <>
                <SwiperSlide key={index}>
                  {dashboard && (
                    <div className='buttons-container'>
                      <div className='edit'>
                        <UpdateText data={pic} picOrder={pic.order || index} totalPictures={data.pictures.length} />
                      </div>
                      <div className='delete'>
                        <Popconfirm
                          title='Are you sure to delete this tap?'
                          onConfirm={() => confirm(pic?._id)}
                          onCancel={cancel}
                          okText='Yes'
                          cancelText='No'
                        >
                          <DeleteFilled />
                        </Popconfirm>
                      </div>
                    </div>
                  )}
                  <LazyLoadImage
                    effect="blur"
                    src={pic?.picture?.url}
                    alt='Uploaded File'
                    className='w-100'
                    style={
                      pic?.cover === "true"
                        ? { objectFit: "cover" }
                        : { objectFit: "contain" }
                    }
                  />
                  {renderButton(pic, index)}

                  {/* <button
                    className='btn text-white example-btn'
                    style={{
                      background: `rgba(${finalColor?.r}, ${finalColor?.g}, ${finalColor?.b}, ${finalColor?.a})`, 
                      fontFamily: `${themeFont}`
                    }}
                    >
                      {data.pictures[index].title}
                    </button>  */}
                </SwiperSlide>
                
              </>
            );
          })}
      </Swiper>
      {!dashboard &&
        (!noBuyButton ? (
          <div className='buy-section w-100 my-2'>
            <div className='buttons'>
              <div>
                <Link
                  className='btn btn-dark'
                  to={'/checkout/' + data?.username + '/' + 3}
                >
                  Start for free
                </Link>
              </div>
              <div>
                <Link
                  className='btn btn-dark'
                  to={'/checkout/' + data?.username + '/' + 10}
                >
                  Buy for $2.99
                </Link>
              </div>
              <div>
                <Link
                  className='btn btn-dark'
                  to={'/checkout/' + data?.username + '/' + 20}
                >
                  Buy for $9.99
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className='fs-4 text-center'>
            Total Price:{' '}
            {qty === '3'
              ? 'Free'
              : qty === '10'
              ? `$2.99`
              : qty === '20' && '$9.99'}
          </div>
        ))}
    </div>
  );
};
