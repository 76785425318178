import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ProfileOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";

import "./Navbar.css";
import Hamburger from "../Hamburger/Hamburger";
import PanelMenu from "../PanelMenu/PanelMenu";

import { isAuthenticated, logout } from "../../components/Auth/auth";

const menuItems = [
  {
    key: "1",
    label: `Hello, ${isAuthenticated().fullName}`,
  },
  {
    key: "2",
    label: <Link to="/dashboard">Dashboard</Link>,
  },
  {
    key: "3",
    label: <Link to="/analytics">Analytics</Link>,
  },
  {
    key: "4",
    label: <Link to="/account">Account</Link>,
  },
  {
    key: "4",
    label: (
      <div
        style={{
          borderBottom: "1px solid #eaeaec",
          paddingTop: "5px",
        }}
      ></div>
    ),
  },
  {
    key: "5",
    label: (
      <a
        href="/login"
        onClick={() => {
          logout(() => {});
        }}
      >
        Logout
      </a>
    ),
  },
];

const Navbar = ({ showInfoIcon }) => {
  const [panelVisible, setPanelVisible] = useState(false);
  const history = useHistory();

  const togglePanel = () => {
    setPanelVisible(!panelVisible);
  };

  const menu = (
    <div className="navMenu">
      {isAuthenticated() ? <Menu items={menuItems} /> : ""}
    </div>
  );

  const handleLogoClick = () => {
    const element = document.getElementById('intro');
      if (history.location.pathname !== '/') {
          history.push('/'); // Navigate to the root path
      }
      if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
      }
  };


    // Effect hook to add or remove class from body based on authentication status
    useEffect(() => {
      if (isAuthenticated()) {
        document.body.classList.add("logged-in");
      } else {
        document.body.classList.remove("logged-in");
      }
  
      // Cleanup function to remove the class when the component is unmounted
      return () => {
        document.body.classList.remove("logged-in");
      };
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts
  

  return (
    !showInfoIcon && (
      <nav>
        <div className="nav-meta">
          <div className="logo-meta">
            <>
              <div onClick={handleLogoClick}  className="fs-4" style={{ zIndex: "1000" }}>
                <img
                  className="copy-logo"
                  src={
                    "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1682475548/logo-diff_duo0ed.png"
                  }
                  alt="Tapfolio Logo"
                  width="35"
                  height="35"
                />
              </div>

              {!isAuthenticated() && (
                <ul className="menu">
                  <li>
                  <a href="/#highlights">Discover</a>
                </li>
                  <li>
                    <a href="/#community">Community</a>
                  </li>
                  {/* <li>
                    <a href="/#better">Examples</a>
                  </li> */}
                  <li>
                    <a href="/#analytics-opener">Analytics</a>
                  </li>
                  <li>
                    <a href="/#pricing">Features</a>
                  </li>
                  <li>
                    <a href="/login">Login</a>
                  </li>
                  <li>
                    <a className="sign" href="/signup">Sign Up Free</a>
                  </li>
                </ul>
              )}

            </>
          </div>

          {isAuthenticated() && (
            <div className="d-flex gap-4 align-items-center profile-nav">
              <div className="auth-icon">
                <Dropdown overlay={menu}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <ProfileOutlined className="account-icon" />
                    <span style={{ fontSize: "14px" }}>Profile</span>
                  </a>
                </Dropdown>
              </div>
            </div>
          )}
        </div>

        {!isAuthenticated() && (
          <>
            {/* Shows only on homepage */}
            {window.location.pathname === "/" && (
              <div className="d-flex gap-4 align-items-center middle-items">
                <div className="auth-icon">
                  <div>
                    <ul className="menu">
                      <li>
                        <a href="/login">Login</a>
                      </li>
                      <li>
                        <a className="sign-up-free" href="/signup">
                          Sign up free
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            <Hamburger toggled={panelVisible} onToggle={togglePanel} />
            <PanelMenu show={panelVisible} onClose={togglePanel}>
              <ul className="menu">
              <li>
                  <a href="/#highlights">Discover</a>
                </li>
                <li>
                  <a href="/#community">Community</a>
                </li>
                {/* <li>
                  <a href="/#better">Examples</a>
                </li> */}
                <li>
                  <a href="/#analytics-opener">Analytics</a>
                </li>
                <li>
                  <a href="/#pricing">Features</a>
                </li>
                <li>
                  <a href="/login">Login</a>
                </li>
              </ul>
            </PanelMenu>
          </>
        )}
      </nav>
    )
  );
};

export default Navbar;
