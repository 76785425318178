import axios from "axios";

const init = async (payload) => {
  return new Promise((resolve) => {
    axios
      .post(`/api/analytics/init`, payload)
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem(
            "INIT",
            JSON.stringify({ ...payload, sessionId: res.data.data?._id })
          );
          resolve(res.data);
          return;
        }
        resolve(null);
      })
      .catch((err) => {
        resolve(null);
      });
  });
};

const event = async (event, key, tapUrl) => {
  return new Promise((resolve) => {
    axios
      .post(`/api/analytics/event/${key}`, {
        ...JSON.parse(sessionStorage.getItem("INIT")),
        tapUrl,
        event,
      })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        resolve(null);
      })
      .catch((err) => {
        resolve(null);
      });
  });
};

const onClickLink = async (link, key, tapUrl) => {
  if (link) {
    await event("CLICK", key, tapUrl);
  }
  return true
};

const onClickSwipe = async (key, tapUrl) => {
  if (tapUrl) {
    await event("SWIPE", key, tapUrl);
  }
};

export { init, event, onClickLink, onClickSwipe };
