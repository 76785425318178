import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

export const NotFound = (props) => {
    useState(() => {
        props.updateNavbar()
    }, [])
    return (
        <div className='notfound'>
            <h2>Tapfolio Not Found</h2>
            <p>Check the web address and try again.</p>
            <p>Back to <span><Link to="/">home</Link></span></p>
        </div>
    );
};
