import ReactGA from 'react-ga4';
const TRACKING_ID = process.env.REACT_APP_GA_ID; // OUR_TRACKING_ID

const isProd = window.location.host === 'tapfol.io'

function initializeGA() {
    if (isProd) {
        // Initialize prod mode
        ReactGA.initialize(TRACKING_ID, { gaOptions: { anonymizeIp: true } });
    } else {
        // Initialize test mode
        ReactGA.initialize(TRACKING_ID, {
            testMode: true,
            gaOptions: {
                debug_mode: true,
            },
            gtagOptions: {
                debug_mode: true,
            },
        });
    }
}

function sendEvent(category, action, label, value = 1) {
    // Send a custom event
    ReactGA.event({
        category, // "your category",
        action, // "your action",
        label, // "your label",
        value, // optional, must be a number
    });
}

const Analytics = {
    initializeGA,
    sendEvent
}

export default Analytics