import React from "react";
import Column from "./Column";
import "./Columns.css";

const Columns = ({ items, columns, title }) => {
  return (
    <div className="columns-container">
      {title && <h2>{title}</h2>}
      <div className="meta">
        {items.map((item, index) => (
          <Column
            key={index}
            title={item.title}
            image={item.image}
            text={item.text}
            link={item.link}
            buttonText={item.buttonText}
            columns={columns}
          />
        ))}
      </div>
    </div>
  );
};

export default Columns;
