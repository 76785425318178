import React, { useState } from "react";
import "./Faq.css";

const FAQBlock = ({ title, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <div className="faq-block">
      <div className="faq-header" onClick={handleToggle}>
        <h3>{title}</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className={isOpen ? "faq-icon rotate" : "faq-icon"}
        >
          <path d="M7 10l5 5 5-5z" />
        </svg>
      </div>
      <div className={`faq-content ${isOpen ? "open" : ""}`}>
        <p dangerouslySetInnerHTML={{__html: text}}></p>
      </div>
    </div>
  );
};

export default FAQBlock;
