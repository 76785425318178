import React, { useState, useEffect } from 'react';

const NumberFormatter = ({ number }) => {
  const [displayNumber, setDisplayNumber] = useState(0);
  const totalDuration = 150; // Total duration of the animation in milliseconds
  const steps = 75; // Number of steps in the animation

  useEffect(() => {
    const incrementPerStep = (number - displayNumber) / steps;
    const intervalDuration = totalDuration / steps; // Duration of each interval step

    const interval = setInterval(() => {
      setDisplayNumber(prevDisplayNumber => {
        let nextDisplayNumber = prevDisplayNumber + incrementPerStep;
        if ((incrementPerStep > 0 && nextDisplayNumber > number) || (incrementPerStep < 0 && nextDisplayNumber < number)) {
          nextDisplayNumber = number;
        }
        return nextDisplayNumber;
      });
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [number, displayNumber]);

  const formatLargeNumber = (inputNumber) => {
    const magnitudes = ['', 'k', 'm', 'b', 't']; // Add more magnitudes as needed
    let magnitude = 0;
    let numberToFormat = inputNumber;

    while (Math.abs(numberToFormat) >= 1000 && magnitude < magnitudes.length - 1) {
      numberToFormat /= 1000;
      magnitude += 1;
    }

    // Adjust decimal precision if the number is over 1k and not rounded
    if (magnitude > 0 && displayNumber !== number) {
      numberToFormat = Math.round(numberToFormat * 10) / 10;
    } else {
      numberToFormat = Math.round(numberToFormat);
    }

    // Convert number to string and append magnitude symbol
    return numberToFormat + magnitudes[magnitude];
  };

  // Use rounding based on whether the current display number equals the target number
  const animatedNumber = formatLargeNumber(displayNumber);

  return <span>{animatedNumber}</span>;
};

export default NumberFormatter;
