import { EditOutlined } from '@ant-design/icons';
import { Modal, Switch } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Error, Success } from '../../Messages/messages';
import imageCompression from 'browser-image-compression';
import './Upload.css'

const UpdateText = ({ data, picOrder, totalPictures }) => {
  const [loading, setLoading] = useState(false);
  const [compressing, setCompressing] = useState(false);
  const [error, setError] = useState('')
  const [order, setOrder] = useState();
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cover, setCover] = useState('false');
  const [compressedFile, setCompressedFile] = useState();

  const showModal = (d) => {
    setError('')
    setTitle(d?.title || '')
    setDescription(d?.text || '');
    setCover(d?.cover || '');
    setUrl(d?.link || '');
    setOrder(picOrder || 1)
    setIsModalOpen(true);
    setCompressedFile(null)
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("file", compressedFile);
    formData.append("cover", cover);
    formData.append("link", url);
    formData.append("order", parseInt(order));
    setError('')
    await axios
      .put(`/api/pictures/update/${data._id}`, formData, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          Success(res.data.successMessage);
          setDescription('');
          setTitle('');
          handleCancel();
          document.location.reload();
        } else {
          Error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.errorMessage || 'Error updating image.');
        setLoading(false);
      });
  };

  function handleImageUpload(event) {
    setCompressing(true)
    var imageFile = event.target.files[0];
    var controller = new AbortController();
    var options = {
      // other options here
      signal: controller.signal,
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
    };
    imageCompression(imageFile, options)
      .then(function (compressedFile) {
        setCompressing(false)
        setError('')
        return setCompressedFile(compressedFile); // write your own logic
      })
      .catch(function (error) {
        setCompressing(false)
        console.error(error); // output: error occured
      });

    // simulate abort the compression after 5 seconds
    // setTimeout(function () {
    //     controller.abort(new Error('taking a little longer...'));
    // }, 5000);
  }

  const getOrderOptions = () => {
    const options = []
    for (let i = 1; i <= totalPictures; i++) {
      options.push(<option value={i}>{i}</option>)
    }
    return options
  }

  const getSubmitButton = () => {
    if (loading) {
      return (<button disabled={true} className='btn btn-primary w-100'>
        Updating...
      </button>)
    } else if (compressing) {
      return (<button disabled={true} className='btn btn-primary w-100'>
        Compressing...
      </button>)
    } else {
      return (<button type='submit' className='btn btn-primary w-100'>
        Update
      </button>)
    }
  }

  useEffect(() => {
    setError('')
  }, [description, compressedFile, title, url])

  return (
    <>
      <EditOutlined onClick={() => showModal(data)} />
      <Modal
        footer={false}
        open={isModalOpen}
        destroyOnClose
        onCancel={handleCancel}
      >
        <form onSubmit={submitHandler}>
          <h4 className='text-center'>Edit Tap</h4>
          <div className='my-3'>
            <label>Replace Image</label>
            <input
              className='form-control'
              name='file'
              type='file'
              id='formFile'
              onChange={handleImageUpload}
              accept='.jpg,.png,.jpeg,.heic'
            />
          </div>
          <div className='form-floating mb-3'>
            <input
              type='text'
              value={title}
              maxLength={32}
              name='title'
              className='form-control'
              id='floatingInput'
              placeholder='Image Title'
              onChange={(e) => setTitle(e.target.value)}
            />
            <label for='floatingInput'>Title (32 character limit)</label>
          </div>
          <div className='form-floating mb-3'>
            <input
              type='url'
              value={url}
              maxLength={500}
              name='url'
              className='form-control'
              id='floatingInput'
              placeholder='Image url'
              onChange={(e) => setUrl(e.target.value)}
            />
            <label for='floatingInput'>URL</label>
          </div>
          <div className='form-floating mb-3'>
            <select
              id='floatingSelect'
              className='form-control'
              value={order}
              onChange={(e) => setOrder(e.target.value)}
            >
              {getOrderOptions()}
            </select>
            <label for='floatingSelect'>
              Select Order
            </label>

          </div>
          <div className='form-floating mb-3'>
            <textarea
              type='text'
              value={description}
              maxLength={50}
              className='form-control'
              style={{ minHeight: '130px' }}
              id='floatingInput'
              placeholder='Description'
              onChange={(e) => setDescription(e.target.value)}
            />
            <label for='floatingInput'>
              Description (50 character limit)
            </label>
            <p className='text-muted text-end'>{description.length} / 50</p>
          </div>
          <div className='mb-3 text-dark'>
            <span>Full Screen Image </span>
            <Switch
              defaultChecked={cover === 'true' ? true : false}
              onChange={(value) => setCover(value)}
            />
          </div>
          <p className='text-danger'
            dangerouslySetInnerHTML={{ __html: error }}
          />
          <div>
            {getSubmitButton()}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UpdateText;
