import React from 'react';
import './Opener.css';

const Opener = ({ text, image, title }) => {
      // Determine if the image is present
      const hasImage = image && image.src;

      // Apply different class if no image is present
      const openerClass = hasImage ? "opener" : "opener no-image";

    return (
        <div className={openerClass}>
            {/* Render images only if image.src is available */}
            {image && (
                <img width="1400" height="850" src={image.src} alt={image.alt} className="image media" />
            )}
            <div className='meta'>
                <div className="title" dangerouslySetInnerHTML={{ __html: title }} />
                <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </div>
    );
};

export default Opener;
