import axios from "axios";
import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading/Loading";
import "./AnalyticsPage.css";
import NumberFormatter from "../../utility/numberFormatter";

const AnalyticsPage = () => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState({});

  const getAnalytics = async (period) => {
    setLoading(true);
    await axios
      .get(`/api/analytics?period=${period}`)
      .then((res) => {
        if (res.status === 200) {
          setAnalytics(res.data.analytics);
        } else {
          setError(res.data.error);
        }
        setLoading(false);
      })
      .catch((e) => {
        setError(e.response.data.error || e.response.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAnalytics("month1");
  }, [token]);

  useEffect(() => {
    console.log(analytics.numberOfDays);
  }, [analytics]);

  return (
    <div className="analytics-page page justify-content-center">
      {loading && <Loading />}
      <h4 id="acccount" className="text-center mb-0">
        Analytics
      </h4>
      {!loading && error && (
        <p className="error mt-5 mb-3">
          {error}
          <br />
          <button
            onClick={() => (window.location = "/account#subscribe")}
            className="btn mt-4 btn-primary"
          >
            Subscribe Now
          </button>
        </p>
      )}
      {!loading && !error && (
        <>
          <div className="options mt-4">
            <div
              className={`${analytics.numberOfDays === 1 ? "selected" : ""}`}
              onClick={() => getAnalytics("day1")}
            >
              1 Day
            </div>
            <div
              className={`${analytics.numberOfDays === 7 ? "selected" : ""}`}
              onClick={() => getAnalytics("week1")}
            >
              1 Week
            </div>
            <div
              className={`${analytics.numberOfDays === 30 ? "selected" : ""}`}
              onClick={() => getAnalytics("month1")}
            >
              1 Month
            </div>
            <div
              className={`${analytics.numberOfDays === 365 ? "selected" : ""}`}
              onClick={() => getAnalytics("year1")}
            >
              1 Year
            </div>
          </div>
          <p className="heading mt-4 viewing-date">
            {new Date(analytics.fromDate).toDateString()}
          </p>
          <div className="stats mt-4">
            <div className="stats-card">
              <p className="heading">Views</p>
              <p className="count">
                <NumberFormatter
                  number={
                    analytics.NEW_USER_COUNT + analytics.RETURNING_USER_COUNT
                  }
                />
              </p>
            </div>
            {/* <div className="stats-card">
              <p className="heading">New Views</p>
              <p className="count">{analytics.NEW_USER_COUNT}</p>
            </div> */}
            <div className="stats-card">
              <p className="heading">Swipes</p>
              <p className="count">
                <NumberFormatter
                  number={analytics.SWIPE_NEXT + analytics.SWIPE_PREV}
                />
              </p>
            </div>
            <div className="stats-card">
              <p className="heading">Link Taps</p>
              <p className="count">
                <NumberFormatter number={analytics.CLICK_IMAGE_COUNT} />
              </p>
            </div>
            <div className="stats-card">
              <p className="heading">Social Taps</p>
              <p className="count">
                <NumberFormatter
                  number={
                    analytics.CLICK_SOCIAL_TIKTOK_COUNT +
                    analytics.CLICK_SOCIAL_LINKEDIN_COUNT +
                    analytics.CLICK_SOCIAL_YOUTUBE_COUNT +
                    analytics.CLICK_SOCIAL_TWITTER_COUNT +
                    analytics.CLICK_SOCIAL_EMAIL_COUNT +
                    analytics.CLICK_SOCIAL_INSTAGRAM_COUNT +
                    analytics?.CLICK_SOCIAL_THREADS_COUNT
                  }
                />
              </p>
            </div>
            <div className="stats-card big">
              <p className="heading">Engagement Breakdown</p>
              <div className="meta">
                {analytics.TOP_CLICKED_TAPS &&
                  analytics.TOP_CLICKED_TAPS.map((tap, index) => (
                    <p className="inner-stat" id={tap._id} >
                      {index + 1}.{" "}
                      <img height={70} src={tap._id} alt="Tap Img"/>
                      &nbsp; <NumberFormatter number={tap.count} />{" "}
                      {tap.count === 1 ? "Tap" : "Taps"}
                    </p>
                  ))}
              </div>
            </div>
            <div className="stats-card big">
              <p className="heading">Referrals</p>
              {analytics.TOP_REFERRERS &&
                analytics.TOP_REFERRERS.map((referrer, index) => {
                  // Extract the domain from the referrer URL
                  const domain = new URL(referrer._id).hostname;

                  // Exclude "tapfol.io" domain
                  if (domain === 'tapfol.io' || domain === 'localhost') {
                    return null; // Skip this referrer
                  }

                  return (
                    <p className="inner-stat refs">
                      {index + 1}. &nbsp;
                      {domain}
                    </p>
                  );
                })
              }

            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AnalyticsPage;
