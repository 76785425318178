import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { isAuthenticated } from '../../components/Auth/auth';
import UploadForm from '../../components/Dashboard/UploadForm/UploadForm';
import Loading from '../../components/Loading/Loading';
import { Link } from 'react-router-dom';

import { Error, Success } from '../../components/Messages/messages';
import { ProductCard } from '../../components/Products/ProductCard';
import FontSelector from '../../components/FontSelector/FontSelector';
import ButtonSelector from '../../components/ButtonSelector/ButtonSelector';
// import Modal from '../../components/Modal/Modal';
import { AlphaPicker, CirclePicker, ChromePicker } from 'react-color';
import './Dashboard.scss';
import tinycolor from 'tinycolor2';

import { Switch } from 'antd';

const Dashboard = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [finalColor, setFinalColor] = useState();
  const [themeFont, setFont] = useState('');
  const [buttonType, setButtonType] = useState('');
  const [bg, setBg] = useState('#000');
  const [user, setUser] = useState();
  const [showCustomPicker, setShowCustomPicker] = useState(false);
  const [isTextLight, setIsTextLight] = useState(false);

  const [panelState, setPanelState] = useState({
    isOpen: false,
    content: null,
  });

  // Open panel with specific content
  const openPanel = (contentType) => {
    setPanelState({ isOpen: true, content: contentType });
  };

  // Close panel
  const closePanel = () => {
    setPanelState({ isOpen: false, content: null });
  };

  // Handling outside click
  useEffect(() => {
    function handleClickOutside(event) {
      const elements = document.getElementsByClassName('sliding-panel');
      let isClickInsidePanel = false;
    
      for (let element of elements) {
        if (element.contains(event.target)) {
          isClickInsidePanel = true;
          break;
        }
      }
    
      if (!isClickInsidePanel) {
        closePanel();
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    
    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Render content based on type
  const renderPanelContent = () => {
    switch (panelState.content) {
      case 'buttons':
        return (
          <>
            <div className="inner-slider">
              <ButtonSelector
                data={{ buttonType: buttonType }}
                onStyleChange={handleButtonStyleChange}
              />
              <h6 className="text-centern mt-3">Light/Dark Text</h6>
              <div className="dark-text mb-3">
                <span>Dark Text</span>
                <Switch checked={isTextLight} onChange={toggleSwitch} />
              </div>
             
            </div>
          </>
        );
      case 'fonts':
        return (
          <>
            <FontSelector
              onFontSelect={(themeFont) => setFont(themeFont)}
              themeFont={themeFont}
            />
          </>
        );
      case 'colors':
        return (
          <>
            <div className="inner-slider">
              <CirclePicker
                color={finalColor}
                circleSize={36}
                onChange={handleColorChange}
                colors={defaultColors}
              />

              <br />

              {!showCustomPicker && (
                <button
                  className="btn mt-4 text-white brand-btn"
                  onClick={() => setShowCustomPicker(true)}
                >
                  Custom Color
                </button>
              )}

              {showCustomPicker && (
                <div className="custom-color-picker">
                  <ChromePicker
                    onChange={(value) => setFinalColor(value.rgb)}
                    color={finalColor}
                    disableAlpha={true}
                  />
                </div>
              )}
              <h6 className="text-center mt-4">Transparency</h6>

              <div className="my-4 trans-picker">
                <AlphaPicker
                  onChange={(value) => setFinalColor(value.rgb)}
                  color={finalColor}
                />
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const getAllPictures = async () => {
    setLoading(true);
    await axios
      .get(`/api/pictures/user/${isAuthenticated()._id}`)
      .then(async (res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data && res.data.pictures) {
            res.data.pictures.sort(function (a, b) {
              var keyA = a.order || 1,
                keyB = b.order || 1;
              // Compare the 2 order
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            });
          }
          setData(res.data);
          setBg(res.data?.themeColor);
          setFinalColor(res.data?.themeColor);

          const color = tinycolor(res.data?.themeColor);
          const rgb = color.toRgb();

          setIsTextLight(res.data?.isTextLight);
          setFont(res.data?.themeFont); // Set the theme font here
          setButtonType(res.data?.buttonType); // Set the theme font here

          // Specfic color check
          if (
            rgb.r === 238 &&
            rgb.g === 238 &&
            rgb.b === 238 &&
            color.isLight()
          ) {
            // This check all colors
            // if (color.isLight()) {
            // add a class to the body element
            document.body.classList.add('light-theme');
          }
        } else {
          if (res.data && res.data.errorMessage) {
            Error(res.data.errorMessage);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getUser = async () => {
    await axios
      .get(`/api/users/user/${isAuthenticated().email}`)
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data);
          Success(res.data.successMessage);
        } else {
          Error(res.data.errorMessage);
        }
      });
  };

  useEffect(() => {
    console.info('isAuthenticated()', !!isAuthenticated());
    getUser();
    getAllPictures();

    props.updateNavbar(false);
  }, []);

  useEffect(() => {}, [user]);

  const submitHandler = async (e) => {
    e.target.blur();
    setLoading(true);
    setShowCustomPicker(false);

    await axios
      .put(
        `/api/pictures/theme/update`,
        {
          themeColor: finalColor,
          isTextLight: isTextLight,
          buttonType: buttonType, // Ensure this is the updated state
          themeFont,
          bg,
        },
        {
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        },
      )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          Success(res.data.successMessage);
        } else {
          Error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        setLoading(false);
        Error(err.data.errorMessage);
      });
  };

  useEffect(() => {
    document.body.classList.add('dashboard-body');

    return () => {
      document.body.classList.remove('dashboard-body');
    };
  }, []);

  // Add state
  useEffect(() => {
    if (isTextLight) {
      document.body.classList.add('dark-theme-color');
    } else {
      document.body.classList.remove('dark-theme-color');
    }
  }, [isTextLight]);

  // Add state
  const toggleSwitch = () => {
    setIsTextLight(!isTextLight);
    if (!isTextLight) {
      document.body.classList.add('dark-theme-color');
    } else {
      document.body.classList.remove('dark-theme-color');
    }
  };

  const handleButtonStyleChange = (style) => {
    setButtonType(style);
  };

  function handleColorChange(color) {
    const newColor = tinycolor(color.hex);
    setFinalColor(newColor.toRgb());
    setBg(newColor.toHexString());

    // Remove existing classes before adding the new one
    document.body.classList.remove('light-theme', 'dark-theme');
    const rgb = newColor.toRgb();
    // Add a class to the body element based on the new color's lightness
    // if (newColor.isLight()) {
    if (rgb.r === 238 && rgb.g === 238 && rgb.b === 238 && newColor.isLight()) {
      document.body.classList.add('light-theme');
    } else {
      document.body.classList.add('dark-theme');
    }
  }

  const defaultColors = [
    '#000000',
    '#EEEEEE',
    '#737373',
    '#07826B',
    '#16d3d3',
    '#276443',
    '#ffb109',
    '#1E59B2',
    '#AB2A2A',
    '#E449A8',
    '#6E38D8',
    '#FF96D7',
    '#574132',
    '#48B9FA',
    '#B66666',
    '#91BBA3',
    '#edca45',
    '#D67229',
  ];

  // 
  useEffect(() => {
    if (themeFont) {
      const fontLink = document.createElement('link');
      fontLink.href = `https://fonts.googleapis.com/css?family=${themeFont}`;
      fontLink.rel = 'stylesheet';
      document.head.appendChild(fontLink);
    }
  }, [themeFont]);

  return (
    <div className="Dashboard account-app">
      <div className="d-flex justify-content-center">
        <div 
              className={`page ${data?.pictures?.length === 0 ? 'empty' : ''}`}
        >
          <h4 className="dashboard-title">Dashboard</h4>
          <p className="intro">Customize your tap slides here</p>

          {/* Loader Overlay */}
          {loading && <Loading />}

          {data?.pictures && data?.pictures.length > 0 ? (
            <div>
              <div className="your-url">
                <a
                  href={`${process.env.REACT_APP_STRIPE_REDIRECT_URL}/${
                    isAuthenticated().username
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`tapfol.io/${isAuthenticated().username}`}
                </a>
              </div>

              <div className="dash-gallery">
                <UploadForm onSuccess={getAllPictures} />

                <ProductCard
                  data={data}
                  dashboard={true}
                  update={getAllPictures}
                  finalColor={finalColor}
                  themeFont={themeFont}
                  buttonType={buttonType}
                />
              </div>
            </div>
          ) : (
            <UploadForm onSuccess={getAllPictures} />
          )}
        </div>
      </div>

      {data?.pictures && data?.pictures.length > 0 ? (
        <>
          <div className="my-4 text-center">
            <div className="color-picker">
              <div className="meta-picker">
                <div>
                  {/* Buttons to open panels */}
                  <div className="toggle-btns">
                    <button
                      className="btn toggle"
                      onClick={() => openPanel('buttons')}
                    >
                      Styles
                    </button>
                    <button
                      className="btn toggle"
                      onClick={() => openPanel('fonts')}
                    >
                      Fonts
                    </button>
                    <button
                      className="btn toggle"
                      onClick={() => openPanel('colors')}
                    >
                      Colors
                    </button>
                  </div>

                  {/* Sliding Panel */}
                  <div
                    className={`sliding-panel ${
                      panelState.isOpen ? 'open' : ''
                    }`}
                  >
                    <div className="panel-content">
                      <div className="panel-meta">
                        {panelState.isOpen && renderPanelContent()}
                        <div className="slider-buttons">
                          <button
                            className="btn close-btn"
                            onClick={closePanel}
                          >
                            Close
                          </button>
                          <button
                            className="btn btn-dark mt-4 btn-primary"
                            onClick={submitHandler}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="extras">
            <Link
              className="btn btn-primary"
              onClick={() => (window.location = '/account#socials')}
            >
              Add Social Media Links
            </Link>

            <p className="text-center">
              Our app is in beta
              <br />
              For questions related to your account, please email us{' '}
              <a href="mailto:hello@tapfol.io">hello@tapfol.io</a>
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Dashboard;
