// import React, { useEffect, useState } from 'react';
// import './Auth.css';
import './Faq.css';
import FaqBlock from '../../components/Faq/Faq';

export const Faq = () => {
  return (
    <div className='faq-page'>
      <h1>FAQ</h1>
      

     <FaqBlock title="What is Tapfolio?" text="Tapfolio is a way to mix your most important links with engaging visuals. Direct your audience to your socials, website, products, videos and more with imagery and titles." />

     <FaqBlock title="Can I see a sample?" text="Sure! We would love to show you ours: <a target='_blank' href='https://tapfol.io/tapfolio'>@tapfolio</a>" />

     <FaqBlock title="How much does it cost?" text="Effective 2023/2024: Tapfolio is currently free for everyone as we roll out the product. It includes up to ten Tap slides, Custom Colors, Custom Links, Custom Text, Social Links, Unique URL, and Instant Analytics. You can cancel anytime." />

     <FaqBlock title="What is a Tap slide?" text="A Tap slide refers to each slide that contains a title, link, description and imagery of your choice." />

     <FaqBlock title="Can I change my Tapfolio URL?" text="Yes, you can change your Tapfolio URL at any time in your account settings." />

     <FaqBlock title="Can I add branding colors to my Tapfolio?" text="Yes, Tapfolio allows you to add your branding colors to your Tap slides for a cohesive look and feel." />

     <FaqBlock title="Can I reorder my Tap slides?" text="You can easily reorder your taps in Tap slides by updating the order inside your tap." />

     <FaqBlock title="Where do I find my Tapfolio username?" text="Your Tapfolio username can be found in your account settings." />

     <FaqBlock title="Where do I add my social media accounts? " text="You can add your social media accounts to your Tapfolio profile in your dashboard under the 'Account' section" />

     <h2>How To's</h2>
     <FaqBlock title="How I can add a Tap slide?" text="To add a Tap slide in Tapfolio, simply click on the 'Add first Link' or the '+' button in your dashboard." />

     <FaqBlock title="How can I change my theme color?" text="You can easily change your theme color in Tapfolio by going to your dashboard. Theme colors are located below your Tap slides and you can select your desired color from the color options, and click save." />

     <FaqBlock title="How can I share my Tapfolio?" text="Sharing your Tapfolio is easy - simply copy and paste the link to your Tapfolio and share it with your desired audience via email, social media, or any other method you prefer." />

     <FaqBlock title="How can I cancel my account?" text="To cancel your Tapfolio account, please login, go to account, and select close account, then type 'confim' in the popup. You can always make a new account as long as the email address is not already being used." />

     <FaqBlock title="How do I upgrade or downgrade my subscription?" text="Upgrading or downgrading your Tapfolio subscription is easy - simply go to your dashboard then account settings and select the plan that best fits your needs." />
    </div>
  );
};

export default Faq;
