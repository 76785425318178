import React from 'react';
import { ReactComponent as LogoWithIcon } from "../../assets/logo-with-icon.svg";
import './Footer.css';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

function Footer() {
  const history = useHistory();
  const handleLogoClick = () => {
    const element = document.getElementById('intro');
     if (history.location.pathname !== '/') {
          history.push('/'); // Navigate to the root path
      }
      if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
      }
  };
  return (
    <footer className="footer">
      <div className="meta">
        <div
          className='footer-logo'
          onClick={handleLogoClick}>
          <LogoWithIcon className="svg" />
        </div>
        <div className='inner-row'>
          <a href="mailto:hello@tapfol.io">
            contact
          </a>
          <Link
            className=''
            to='/faq'
          >
            faq
          </Link>
          <Link
            className=''
            to='/terms'
          >
            terms
          </Link>
          <Link
            className=''
            to='/privacy'
          >
            privacy
          </Link>
        </div>
        <div className='icons-grid'>
          <a alt="instagram" href="https://instagram.com/tapfol.io?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
            <span>instagram</span>
            <i className='fa fa-instagram'>
            </i>
          </a>
          <a alt="tiktok" href="https://www.tiktok.com/@tapfol.io?_t=8ayhpFjCdBL&_r=1" target="_blank" rel="noreferrer">
            <span>tiktok</span>
            <i className='fab fa-tiktok'>
            </i>
          </a>
        </div>
        <div className='icons-grid'>

        </div>
      </div>
    </footer>
  );
}

export default Footer;
