import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Styles
import './ButtonSelector.scss';

// Utils
import { capitalizeFirstLetter } from '../../utility/capitalizeFirstLetter'; 

// Button styles array
const buttonStyles = ['rounded', 'square', 'mario'];

// Update state when buttonType prop changes
function ButtonStyleSelector({ data: { buttonType }, onStyleChange }) {
  const [selectedStyle, setSelectedStyle] = useState(buttonType || '');

  // Update state when buttonType prop changes
  useEffect(() => {
    setSelectedStyle(buttonType || '');
  }, [buttonType]);

  // Handle style selection and update parent component
  const handleSelectStyle = (style) => () => {
    setSelectedStyle(style); // Update local state
    onStyleChange(style);    // Propagate change to parent component
  };

  // Generate CSS classes for buttons based on selection
  const getButtonClasses = (style) => (
    `styled-btn ${selectedStyle === style ? 'selected' : ''}`
  );

  return (
    <div className="style-selector">
      {buttonStyles.map(style => (
        <button 
          key={style} 
          className={getButtonClasses(style)} 
          onClick={handleSelectStyle(style)}
        >
          {capitalizeFirstLetter(style)}
        </button>
      ))}
    </div>
  );
}

// Prop types for ButtonStyleSelector
ButtonStyleSelector.propTypes = {
  data: PropTypes.shape({
    buttonType: PropTypes.string
  }),
  onStyleChange: PropTypes.func.isRequired
};

// Default props for ButtonStyleSelector
ButtonStyleSelector.defaultProps = {
  data: {}
};

export default ButtonStyleSelector;
