import React from 'react';
import './Hamburger.css';

const Hamburger = ({ toggled, onToggle }) => {
  return (
    <div 
      className={`hamburger ${toggled ? 'toggled' : ''}`} 
      onClick={onToggle}  // Use the passed down onToggle function here
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default Hamburger;
