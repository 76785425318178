import React from 'react';

const Column = ({ title, image, text, columns, link, buttonText }) => {
  const columnClass = text ? 'column' : 'column no-text';
  
  return (
    <div className={columnClass} style={{ flex: columns }}>
      {image && <img width="580" height="460" loading="lazy" src={image} alt={title ? title : 'Image'} />}
      {title && <h3>{title}</h3>}
      {text && <p>{text}</p>}
      {buttonText && <a className="button" target='_blank' href={link}>{buttonText}</a>}
    </div>
  );
};

export default Column;
