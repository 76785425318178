// import React, { useEffect, useState } from 'react';
// import './Auth.css';
import './Terms.css';

export const Terms = () => {
  return (
    <div className='terms-page'>
      <h1>Terms and Conditions of Use</h1>
      <p>Welcome to Tapfolio, an online link building platform (the "Platform"). These Terms and Conditions of Use ("Terms") govern your use of the Platform and any related services, including any updates or enhancements to the Platform, whether made available by us or by our third-party service providers (collectively, the "Service").</p>
      <p>By using the Service, you agree to these Terms. If you do not agree to these Terms, you may not use the Service.</p>
      <h5>User Conduct</h5>
      <p>By using the Service, you agree that you will not:</p>
      <ul>
        <li>Use the Service in a manner that violates any applicable laws or regulations;</li>
        <li>Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
        <li>Use the Service to harass, abuse, or harm another person or entity;</li>
        <li>Attempt to gain unauthorized access to the Service or its related systems or networks;</li>
        <li>Use any automated means to access the Service or its related systems or networks, or use any data mining, robots, or similar data gathering and extraction tools;</li>
        <li>Introduce any viruses, Trojan horses, worms, logic bombs, or other harmful materials into the Service or its related systems or networks; or</li>
        <li>Use the Service for any commercial purpose without our express written consent.</li>
      </ul>
      <h5>Ownership of Content</h5>
      <p>You retain all rights in any content that you upload to the Service ("Content"), and we do not claim ownership of any Content that you upload to the Service.</p>
      <h5>Privacy</h5>
      <p>We value your privacy and do not sell users' data or images to third parties. Our Privacy Policy explains how we collect, use, and disclose information about you when you use the Service, and is incorporated into these Terms by reference.</p>
      <h5>Intellectual Property</h5>
      <p>The Service and its contents are protected by copyright, trademark, and other laws of the United States and foreign countries. Except as expressly authorized by us, you may not copy, modify, distribute, sell, or lease any part of the Service or its contents.</p>
      <h5>Disclaimers and Limitations of Liability</h5>
      <p>THE SERVICE IS PROVIDED "AS IS" AND WITHOUT WARRANTY OF ANY KIND. WE MAKE NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT. WE DO NOT GUARANTEE THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, AND WE WILL NOT BE LIABLE FOR ANY DAMAGES ARISING FROM THE USE OF THE SERVICE, INCLUDING ANY INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES.</p>
      <h5>Indemnification</h5>
      <p>You agree to indemnify, defend, and hold us harmless from any and all claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from your use of the Service, your breach of these Terms, or your infringement of any intellectual property or other right of any person or entity.</p>

      <h5>Modification of Terms</h5>
      <p>
        We reserve the right to modify these Terms at any time, and such
        modifications will be effective immediately upon posting of the modified
        Terms on the Service. Your continued use of the Service following any such
        modification constitutes your acceptance of the modified Terms.
      </p>

      <h5>Governing Law</h5>
      <p>
        These Terms and your use of the Service will be governed by and construed
        in accordance with the laws of the State of California, without giving
        effect to any choice of law or conflict of law provisions.
      </p>

      <h5>Dispute Resolution</h5>
      <p>
        Any dispute arising out of or relating to these Terms or your use of the
        Service will be resolved exclusively through binding arbitration in
        accordance with the rules of the American Arbitration Association.
      </p>

      <h5>Termination</h5>
      <p>
        We may terminate your access to the Service at any time for any reason
        without notice.
      </p>

      <h5>Entire Agreement</h5>
      <p>
        These Terms constitute the entire agreement between you and Tapfolio with
        respect to the use of the Service, and supersede all prior or
        contemporaneous agreements or representations, whether oral or written,
        relating to the Service.
      </p>

      <h5>Waiver and Severability</h5>
      <p>
        Our failure to enforce any right or provision of these Terms will not be
        deemed a waiver of such right or provision. If any provision of these Terms
        is held to be invalid or unenforceable, the remaining provisions of these
        Terms will remain in full force and effect.
      </p>

      <h5>Contact Us</h5>
      <p>
        If you have any questions about these Terms or the Service, please contact
        us at <a href="mailto:hello@tapfol.io">hello@tapfol.io</a>.
      </p>



    </div>
  );
};

export default Terms;
