import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./Auth.css";
import Loading from "../../components/Loading/Loading";
import { Error, Success } from "../../components/Messages/messages";
import ReCAPTCHA from "react-google-recaptcha";
import Columns from "../../components/Sections/Columns";
import { useHistory } from "react-router-dom";

export const Signup = (props) => {
  const captchaRef = useRef(null);
  const getEmail = props.match.params.email;
  const UserName = props.match.params.userName;
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [userData, setUserData] = useState({
    fullName: "",
    email: getEmail ? getEmail : "",
    username: UserName ? UserName : "",
    password: "",
    confirm: "",
  });

  const { email, username } = userData;

  const handleChange = (e) => {
    setUserData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const nextStep = () => {
    if (currentStep === 1 && !isValidEmail(userData.email)) {
      Error("Please enter a valid email address.");
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && currentStep < 2) {
      event.preventDefault();  // Prevent the default form submission on Enter
      nextStep();
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    if (!token) {
      Error("ReCaptcha required.");
      return;
    }
    if (userData.password !== userData.confirm) {
      Error("Passwords don't match");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post("/api/users/signup", { ...userData, token });
      setLoading(false);
      if (response.status === 200) {
        Success(response.data.successMessage);
        setTimeout(() => {
          history.push("/login");
        }, 2000);
      } else {
        Error(response.data.errorMessage);
      }
    } catch (e) {
      console.error(e);
      Error(e.response.data.errorMessage);
      setLoading(false);
    }
  };

  const gettingStarted = [
    {
      image:
        "https://plus.unsplash.com/premium_photo-1683134461130-536f275c1ed8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=100",
    },
    {
      image:
        "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1696538637/good-faces-agency-tofAIcnYl0A-unsplash_zk8k9n.jpg",
    },
  ];

  const features = [
    {
      title: "Captivating presentations",
      image:
        "https://images.unsplash.com/photo-1527856263669-12c3a0af2aa6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=100",
      text: "Tapfolio allows you to build compelling and content focused presentations that can quickly showcase your products and services to your clients. With slides incorporating visuals, videos, music, and other elements, you can make a fast and lasting impression.",
    },
    {
      title: "Improve your website traffic",
      image:
        "https://images.unsplash.com/photo-1591696205602-2f950c417cb9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=100",
      text: "Presentations can link to sales pages and your main website to improve traffic for your desired landing pages. These presentations can also be lead pages with links to your sales or e-commerce platform.",
    },
    {
      title: "Easy edits when needed",
      image:
        "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1696551159/linkedin-sales-solutions-zn2aUVfbUrk-unsplash-1_dpwchh.jpg",
      text: "Add or remove slides, introduce new content focused links or products, and update presentations in real time. Change out slides in a few clicks and redirect traffic to unique landing pages or sales pages, all from the Tapfolio dashboard.",
    },
  ];

  const featuresTwo = [
    {
      title: "Custom links and buttons",
      image:
        "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1696538638/robert-horvick-1R4uPYipCFM-unsplash_qphft1.jpg",
      text: "After building your tap through a slideshow presentation, you can create a unique link to it with your brand name. These marketable URLs feature a short and easy-to-type web address that you can share easily through custom buttons. Create custom buttons for in-video links and other content, or share the shortened URL you pick for your branded slideshow.",
    },
    {
      title: "Seamless integration",
      image:
        "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1696538638/le-buzz-studio-aM9sj98dI2A-unsplash_mgavkn.jpg",
      text: "With your unique link, you can get a streamlined integration with social media websites. The small link can be inserted into most bio sections, offering an easy navigation flow to go through tap slides in real time. In minutes, you can update your social profiles to include a link to the presentation and quickly load your Tapfolio profile page to generate interest in your products.",
    },
    {
      title: "Budget-friendly",
      image:
        "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1696538638/ivana-cajina-dnL6ZIpht2s-unsplash_zbozqp.jpg",
      text: "Tapfolio is tailored to fit businesses of all sizes. Users can craft a content-focused presentation with up to three slides for free. For those seeking more, our offering extends to presentations of up to 10 slides, complete with comprehensive analytics for detailed tracking, all at no cost.",
    },
  ];

  const finalImage = [
    {
      image:
        "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1696538638/le-buzz-studio-aM9sj98dI2A-unsplash_mgavkn.jpg",
    },
  ];

  useEffect(() => {
    document.body.classList.add("signup-body");

    return () => {
      document.body.classList.remove("signup-body");
    };
  }, []);

  useEffect(() => {

    const handleScroll = () => {
      // Check if the scroll position is more than the window's inner height
      if (window.scrollY > 0) {
        document.body.classList.add("has-scrolled");
      } else {
        document.body.classList.remove("has-scrolled");
      }
    };
    
    // Function to handle the scroll event
    const handle100vhScroll = () => {
      // Check if the scroll position is more than the window's inner height
      if (window.scrollY > window.innerHeight) {
        document.body.classList.add("has-scrolled-100vh");
      } else {
        document.body.classList.remove("has-scrolled-100vh");
      }
    };

    // Add the 'signup-body' class initially
    document.body.classList.add("signup-body");
    // Add the scroll event listener
    window.addEventListener("scroll", handle100vhScroll);
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      document.body.classList.remove("signup-body");
      window.removeEventListener("scroll", handle100vhScroll);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  return loading ? (
    <Loading />
  ) : (
    <div className="signup-page">
      <div className="bg" />
      <div className="auth">
        <div className="auth-inner-bubble-container">
          <h3>Instant Free Access</h3>
          <p className="sign-up-sub">
            Own yours  today.
          </p>
          {/* Multistep Form */}
          <form onSubmit={submitHandler}>
          {currentStep === 1 && (
            <>
                {/* <div className="item">
                  <input className="form-control" name="fullName" required type="text" placeholder="Name" value={userData.fullName} onChange={handleChange}onKeyDown={handleKeyDown} />
                </div> */}
                <div className="item">
                  <input className="form-control" name="username" required type="text" placeholder="Username" value={userData.username} onChange={handleChange}onKeyDown={handleKeyDown}/>
                </div>
                <div className="item">
                  <input className="form-control" name="email" required type="email" placeholder="Email" value={userData.email} onChange={handleChange} onKeyDown={handleKeyDown} />
                </div>
              <button type="button" onClick={nextStep}>Next</button>
            </>
          )}
          {currentStep === 2 && (
            <>
                <div className="item">
                  <input className="form-control" name="password" required type="password" placeholder="Password" onChange={handleChange} />
                </div>
                <div className="item">
                  <input className="form-control" name="confirm" required type="password" placeholder="Confirm Password" onChange={handleChange} />
                </div>
                
              <div className="captcha-item">
              <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} />
              </div>
              <button type="submit">Sign Up</button>
              <button className="back-btn" type="button" onClick={prevStep}>Back</button>
            </>
          )}
          </form>
        
          {/* Full form */}
          {/* <form onSubmit={submitHandler}>
            <div className="item">
              <label>Name</label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fas fa-user"></i>
                </span>
                <input
                  name="fullName"
                  required
                  type="text"
                  className="form-control"
                  placeholder=""
                  onChange={handleChange}
                  value={userData.fullName}
                />
              </div>
            </div>
            <div className="item">
              <label>Handle (change at anytime)</label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa-regular fa-at"></i>
                </span>
                <input
                  name="username"
                  value={username}
                  required
                  type="text"
                  className="form-control"
                  placeholder=""
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="item">
              <label>Email</label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa-regular fa-envelope"></i>
                </span>
                <input
                  value={email}
                  required
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder=""
                  onChange={handleChange}
                  autoComplete="username"
                />
              </div>
            </div>
            <div className="item">
              <label>Password</label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa-solid fa-lock"></i>
                </span>
                <input
                  name="password"
                  required
                  type="password"
                  className="form-control"
                  placeholder=""
                  pattern=".{8,}"
                  onChange={handleChange}
                  autoComplete="new-password"
                />
              </div>
            </div>
            <div className="item">
              <label>Re-type Password</label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa-solid fa-lock"></i>
                </span>
                <input
                  name="confirm"
                  required
                  type="password"
                  className="form-control"
                  pattern=".{8,}"
                  placeholder=""
                  onChange={handleChange}
                  autoComplete="new-password"
                />
              </div>
            </div>
            <div className="item g-recaptcha">
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              />
            </div>
            <button className="btn" type="submit">
              Signup
            </button>
          </form> */}
        </div>
        <div className="worm-meta">
              <div className="worm bounce" />
            </div>
      </div>
      <div className="plans-info">
        <div className="plans-info-meta">
          <h1>One Free, Complete Plan</h1>
          <p>
              While the service is entirely free, users can craft presentations with up to 10 slides and access our comprehensive analytics suite to monitor progress and identify high-performing slides. This feature-rich offering is perfect for businesses looking to gain insights, making Tapfolio an invaluable tool for optimizing your business.

          </p>

          <Columns items={gettingStarted} columns={2} />

          <h2 className="full-title">The benefits of Tapfolio to enhance your online presence</h2>

          <Columns items={features} columns={3} />

          <Columns items={featuresTwo} columns={3} />

          <h3>Join now and improve your link in bio experience</h3>

          <p>
            Tapfolio empowers brand owners and marketers to create compelling
            content for link-in bio experiences that can help their brand stand
            out. Enhance your brand image with a valuable presentation that is
            easy to navigate and boosts understanding of your product or service.
            The valuable data insights that are captured are an
            excellent way to discover who is most interested in your brand and how
            you can make future content more easily. Join Tapfolio today and see
            the full potential of your link-in bio experience for improving your
            online presence.
          </p>

          {/* <Columns items={finalImage} columns={1} /> */}
   
            <a
              className='cta-signup'
              href="#">
                Sign Up Free
            </a>

            <p className="cta-text">
              No subscription required
            </p>
        </div>
      </div>


    </div>
  );
};
