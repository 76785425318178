import React, { useState, useEffect } from 'react';
import Column from './Column';
import './SlideshowColumns.scss';

const Slideshow = ({ items, columns, title }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState('');
  const itemsPerPage = 4;

  const goToPrevious = () => {
    setFadeClass('fade-out');
    const newIndex = (currentIndex - itemsPerPage + items.length) % items.length;
    setTimeout(() => {
      setCurrentIndex(newIndex);
      setFadeClass('fade-in');
    }, 500); // Match the duration of the fade-out effect
  };

  const goToNext = () => {
    setFadeClass('fade-out');
    const newIndex = (currentIndex + itemsPerPage) % items.length;
    setTimeout(() => {
      setCurrentIndex(newIndex);
      setFadeClass('fade-in');
    }, 500); // Match the duration of the fade-out effect
  };

  const visibleItems = items.slice(currentIndex, currentIndex + itemsPerPage);

  return (
    <div className="columns-container slideshow-columns">
      {title && <h2>{title}</h2>}
        <button className='arrow' onClick={goToPrevious}>&lt;</button>
      <div className={`meta ${fadeClass}`}>
        {visibleItems.map((item, index) => (
          <Column
            key={index}
            title={item.title}
            image={item.image}
            text={item.text}
            link={item.link}
            buttonText={item.buttonText}
            columns={1 / itemsPerPage}
            className="column visible"
          />
        ))}
      </div>
        <button className='arrow right' onClick={goToNext}>&gt;</button>


        <div className='mobile-nav'>
        <button className='arrow' onClick={goToPrevious}>&lt;</button>
        <button className='arrow right' onClick={goToNext}>&gt;</button>
      </div>
    </div>
  );
};

export default Slideshow;
