import React, { useState } from 'react';
import axios from 'axios';
import { Success, Error } from '../Messages/messages';
import { logout } from '../Auth/auth';

function DeleteAccount() {
    const [loading, setLoading] = useState(false)
    const onClickDeleteAccount = () => {
        const userResponse = window.prompt("Are you sure you want to close your account? Your account and all its data will be removed. You can make a new account with the same email address anytime.\n\n Enter 'confirm' in text box below to process.")
        if (userResponse === null) {
            return
        }
        if (userResponse && userResponse.toLowerCase() === 'confirm') {
            setLoading(true)
            axios
                .delete(`/api/users`, {
                    headers: {
                        authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        if(res.data.status) {
                            Success('Account deleted successfully. You will be redirected to home page.')
                            setTimeout(()=> {
                                logout(() => {
                                    window.location = '/'
                                })
                            }, 4000)
                        }
                    } else {
                        console.error(res.data);
                        Error('Error deleting account. Try again later.')
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                    Error('Error deleting account. Try again later.')
                    console.error(err.message);
                });
        } else {
            Error('Missing input "confirm".')
        }
    }
    return (
        <div>
            <h5 className='intro mt-5'>
                Close Account
            </h5>
            <p>
                This action cannot not be redone. 
            </p>
            <button
                disabled={loading}
                className="btn btn-danger"
                onClick={onClickDeleteAccount}
            >{loading ? 'Processing...' : 'Close Account'}</button>
        </div>
    );
}

export default DeleteAccount;
