import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { setAuthentication } from '../../components/Auth/auth';
import './Auth.css';
import Loading from '../../components/Loading/Loading';
import { Error, Success } from '../../components/Messages/messages';
import ReCAPTCHA from "react-google-recaptcha"

export const Login = (props) => {
  const captchaRef = useRef(null)
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = userData;

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    props.updateNavbar(false)
  })

  const submitHandler = async (e) => {
    e.preventDefault()
    window.scrollTo(0, 0);
    const token = captchaRef.current.getValue();
    if(!token) {
      Error("ReCaptcha validation required.");
      return
    }
    setLoading(true);
    await axios
      .post('/api/users/login', { email, password, token })
      .then(async (res) => {
        setLoading(false);
        if (res.status === 200) {
          setAuthentication(res.data, res.data.token);
          Success(res.data.successMessage);
          props.history.push('/dashboard');
          document.location.reload();
        } else {
          Error(res.data.errorMessage);
        }
      }).catch(e => {
        // captchaRef.current.reset();
        console.error(e)
        Error(e.response.data.errorMessage);
        setLoading(false);
      });
  };

  useEffect(() => {
    document.body.classList.add("login-body");

    return () => {
      document.body.classList.remove("login-body");
    };
  }, []);

  return (
    <div className='auth'>
      <div className='bg' />
      <div className='auth-inner-bubble-container'>
        <h3>Login</h3>
        {/* <p>Login with email and password</p> */}
        {loading ? (
          <Loading />
        ) : (
          <form onSubmit={submitHandler}>
            <div className='item'>
              <label>Email or Username</label>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  <i className='fa-regular fa-envelope'></i>
                </span>
                <input
                  name='email'
                  required
                  type='text'
                  className='form-control'
                  placeholder=''
                  onChange={handleChange}
                  aria-label='Username'
                  aria-describedby='basic-addon1'
                />
              </div>
            </div>
            <div className='item'>
              <label>Password</label>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  <i className='fa-solid fa-lock'></i>
                </span>
                <input
                  name='password'
                  required
                  type='password'
                  className='form-control'
                  placeholder=''
                  onChange={handleChange}
                  aria-label='Username'
                  aria-describedby='basic-addon1'
                />
              </div>
            </div>
            <div className='forgot-password'>
              <Link to='/forgot-password'>Forgot password?</Link>
            </div>
            <div className='item g-recaptcha'>
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              />
            </div>
            <button className='btn' type='submit'>
              Login
            </button>
          </form>
        )}
        {/* <div className='end-text'>
          <div>Don't have an account?</div>
          <Link to='/'>
            <b className='fw-bold'>Register for FREE</b>
          </Link>
        </div> */}
      </div>
    </div>
  );
};
