import { toast } from 'react-toastify';

const config = {
  position: "top-center",
  autoClose: 3300,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "light",
}

export const Success = (message) => {
  toast.dismiss()
  toast.success(message, config)
};

export const Error = (message) => {
  toast.dismiss()
  toast.error(message, config)
};

export const Warning = (message) => {
  toast.dismiss()
  toast.warning(message, config)
};
