import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import './Loading.css';

function Loading() {
  const IconLottie = useRef(null)

  useEffect(() => {
    lottie.loadAnimation({
      container: IconLottie.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('./Loading.json')
    })
  }, [])
  
  return (
    <div className="loading">
      <div className="IconLottie" ref={IconLottie}></div>
    </div>
  );
}

export default Loading;
