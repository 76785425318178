import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading/Loading';
import { Error, Success } from '../../components/Messages/messages';
import {
  deleteLocalStorage,
} from '../../components/Auth/localStorage';
import './Account.css';
// import StripeSubscription from '../../components/StripeSubscription';
import DeleteAccount from '../../components/DeleteAccount';
import { ReactComponent as Threads } from "../../assets/threads.svg";

const Account = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [showSavedPasswordMessage, setShowSavedPasswordMessage] = useState(false);

  useEffect(() => {
    if (!user) return;

    setUserData({
      fullName: user.fullName,
      email: user.email,
      username: user.username,
      social: user.social,
      tipUrl: user.tipUrl
    });

  }, [user]);

  const [userData, setUserData] = useState({
    fullName: '',
    username: '',
    social: {},
    tipUrl: ''
  });

  const [userData2, setUserData2] = useState({
    password: '',
    confirm: '',
  });

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeLink = (e) => {
    setUserData({
      ...userData,
      social: { 
        ...userData.social,
        [e.target.name]: e.target.value
      }
    });
  };

  const handleChange2 = (e) => {
    setUserData2({
      ...userData2,
      [e.target.name]: e.target.value,
    });
  };

  const handleAccount = async (e) => {
    setLoading(true);
    e.preventDefault();
    await axios
      .patch(`/api/users/update/${user?.email}`, userData, {
        headers: {
            authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    })
      .then((res) => {
        if (res.status === 200) {
          deleteLocalStorage('user');
          Success('Information updated successfully');
          localStorage.setItem(
            'user',
            JSON.stringify(res?.data?.user)
          );
          localStorage.setItem(
            'token',
            res?.data?.token
          );
          setShowSavedMessage(true); // set the state variable to true
          setTimeout(() => setShowSavedMessage(false), 3000); // fade out the message after 3 seconds

        } else {
        }
      })
      .catch((err) => {
        Error(err.response?.data?.errorMessage || 'Something went wrong');
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const token = JSON.parse(localStorage.getItem('user')).token;
  const email = JSON.parse(localStorage.getItem('user')).email;

  const handlePassword = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`/api/users/update/password/${email}`, {
        password: userData2.password,
        confirm: userData2.confirm,
        token,
      })
      .then((res) => {
        if (res.status === 200) {
          Success(res.data.successMessage);
          setShowSavedPasswordMessage(true); // set the state variable to true
          setTimeout(() => setShowSavedPasswordMessage(false), 3000); // fade out the message after 3 seconds

        } else {
          Error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        Error(err.data?.errorMessage || 'Something went wrong');
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUser = async () => {
    await axios.get(`/api/users/user/${email}`).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setUser(res.data);
        Success(res.data.successMessage);
      } else {
        Error(res.data.errorMessage);
      }
    });
  };
  useEffect(() => {
    getUser();
  }, [email]);

  useEffect(() => {
    document.body.classList.add("account-body");

    return () => {
      document.body.classList.remove("account-body");
    };
  }, []);

  return (
    <div>
      {/* {contextHolder} */}
      <div className='account-app page account-page d-flex justify-content-center'>
        <div>
          <h4 id="acccount" className='text-center mb-0'>Account</h4>
          <p className='intro'>
            Add your social media links, upgrade your account, or update your password.
          </p>
          <form onSubmit={handleAccount}>
          <div className='item mt-2'>
              <label>Name</label>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  <i className='fas fa-user'></i>
                </span>
                <input
                  value={userData?.fullName}
                  name='fullName'
                  type='text'
                  className='form-control'
                  placeholder='Full Name'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='item mt-2'>
              <label>Handle</label>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  <i className='fas fa-at'></i>
                </span>
                <input
                  value={userData?.username}
                  name='username'
                  required
                  type='text'
                  className='form-control'
                  placeholder='User Name'
                  onChange={(e) => {handleChange({target: {
                    name: e.target.name,
                    value: e.target.value.toLowerCase().replace(/\s/g, '')
                  }})}}
                />
              </div>
            </div>
            <div className='item mt-2'>
              <label>Email</label>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  <i className='fas fa-envelope'></i>
                </span>
                <input
                  defaultValue={userData?.email}
                  name='email'
                  type='email'
                  disabled
                  className='form-control'
                  placeholder='Email'
                />
              </div>
            </div>

            <button className='btn btn-primary mt-3' type='submit'>
              {showSavedMessage ? (
                <div className="saved-message">
                  Updated!
                </div>
              ) : (
                <div className="not-saved-message">
                  Update Information
                </div>
              )}
            </button>
            

            <a id="socials">Anchor</a>
            <h5 className='mt-5 intro'>Social Links</h5>
            <p className='intro'>
                  Use the full link including https://
                </p>
            <div className='item mt-2'>
              <label className='mt-1'>Tiktok URL</label>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  <i className="fa-brands fa-tiktok"></i>
                </span>
                <input
                  value={userData?.social?.tiktok}
                  name='tiktok'
                  type='url'
                  pattern="https?://(?:www\.)?tiktok\..*"
                  className='form-control'
                  onChange={handleChangeLink}
                />
              </div>
            </div>
            <div className='item mt-2'>
              <label className='mt-1'>Instagram URL</label>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  <i className="fa-brands fa-instagram"></i>
                </span>
                <input
                  value={userData?.social?.instagram}
                  name='instagram'
                  type='url'
                  pattern="https?://(?:www\.)?instagram\..*"
                  className='form-control'
                  onChange={handleChangeLink}
                />
              </div>
            </div>
            <div className='item mt-2'>
              <label className='mt-1'>Threads URL</label>
              <div className='input-group '>
                <span className='input-group-text' id='basic-addon1'>
                  <Threads className="threads" />
                </span>
                <input
                  value={userData?.social?.threads}
                  name='threads'
                  type='url'
                  pattern="https?://(?:www\.)?threads\..*"
                  className='form-control'
                  onChange={handleChangeLink}
                />
              </div>
            </div>
            <div className='item mt-2'>
              <label className='mt-1'>LinkedIn URL</label>
              <div className='input-group '>
                <span className='input-group-text' id='basic-addon1'>
                  <i className="fa-brands fa-linkedin"></i>
                </span>
                <input
                  value={userData?.social?.linkedin}
                  name='linkedin'
                  type='url'
                  pattern="https?://(?:www\.)?linkedin\..*"
                  className='form-control'
                  onChange={handleChangeLink}
                />
              </div>
            </div>
            <div className='item mt-2'>
              <label className='mt-1'>Youtube URL</label>
              <div className='input-group '>
                <span className='input-group-text' id='basic-addon1'>
                  <i className="fa-brands fa-youtube"></i>
                </span>
                <input
                  value={userData?.social?.youtube}
                  name='youtube'
                  type='url'
                  pattern="https?://(?:www\.)?youtube\..*"
                  className='form-control'
                  onChange={handleChangeLink}
                />
              </div>
            </div>
            <div className='item mt-2'>
              <label className='mt-1'>X (Formerly Twitter) URL</label>
              <div className='input-group '>
                <span className='input-group-text' id='basic-addon1'>
                  <i className="fa-brands fa-x-twitter"></i>
                </span>
                <input
                  value={userData?.social?.twitter}
                  name='twitter'
                  type='url'
                  pattern="https?://(?:www\.)?(twitter\.|x\.com).*"
                  
                  className='form-control'
                  placeholder=''
                  onChange={handleChangeLink}
                />
              </div>
            </div>
            <div className='item mt-2'>
              <label className='mt-1'>Contact Email</label>
              <div className='input-group '>
                <span className='input-group-text' id='basic-addon1'>
                  <i className="fas fa-envelope"></i>
                </span>
                <input
                  value={userData?.social?.email}
                  name='email'
                  type='email'
                  className='form-control'
                  placeholder='contact@test.com'
                  onChange={handleChangeLink}
                />
              </div>
            </div>
            
            <div className='item mt-2'>
              <label className='mt-1'>Tip URL</label>
              <p>venmo.com, cash.app, tiptopjar.com</p>
              <div className='input-group '>
                <span className='input-group-text' id='basic-addon1'>
                  <i className="fas fa-dollar-sign"></i>
                </span>
                <input
                  value={userData?.social?.tipUrl}
                  name='tipUrl'
                  type='url'
                  placeholder=''
                  className='form-control'
                  pattern="https?://(?:www\.)?(venmo\.com|cash\.app|tiptopjar\.com).*"
                  onChange={handleChangeLink}
                />
              </div>
            </div>

            <button className='btn btn-primary mt-3' type='submit'>
              {showSavedMessage ? (
                <div className="saved-message">
                  Updated!
                </div>
              ) : (
                <div className="not-saved-message">
                  Update Information
                </div>
              )}
            </button>

            {loading && <Loading />}

          </form>

          {/* <StripeSubscription /> */}

          <form className='mt-5' onSubmit={handlePassword}>
            <h5 className='intro'>
              Change Password
            </h5>
            <div className='item mt-2'>
              <label>Password (at least 8 characters)</label>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  <i className='fa-solid fa-lock'></i>
                </span>
                <input
                  name='password'
                  required
                  type='password'
                  className='form-control'
                  placeholder='Password'
                  pattern=".{8,}"
                  onChange={handleChange2}
                  value={userData2.password}
                  autoComplete="new-password"
                />
              </div>
            </div>
            <div className='item mt-2'>
              <label>Re-type Password</label>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  <i className='fa-solid fa-lock'></i>
                </span>
                <input
                  name='confirm'
                  required
                  type='password'
                  className='form-control'
                  placeholder='Retype Password'
                  pattern=".{8,}"
                  onChange={handleChange2}
                  value={userData2.confirm}
                  autoComplete="new-password"
                />
              </div>
            </div>
            <button className='btn btn-primary mt-3' type='submit'>

              {showSavedPasswordMessage ? (
                <div className="saved-message">
                  Updated!
                </div>
              ) : (
                <div className="not-saved-message">
                  Update Password
                </div>
              )}
            </button>
          </form>

          <DeleteAccount />
          <div className="extras">
            <p>
              For questions related to your account, please email us <a href="mailto:hello@tapfol.io">hello@tapfol.io</a>
            </p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Account;
