import {
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import GlobalFooter from './components/Footer/Footer';
import { SendResetPasswordLink } from './pages/Auth/Forgot-Password/SendResetPasswordLink';
import { UpdatePassword } from './pages/Auth/Forgot-Password/UpdatePassword';
import { Login } from './pages/Auth/Login';
import Faq from './pages/Faq/Faq';
import Privacy from './pages/Privacy/Privacy';
import Terms from './pages/Terms/Terms';
import { Signup } from './pages/Auth/Signup';
import Dashboard from './pages/Dashboard/Dashboard';
import Account from './pages/Account/Account';
import Home from './pages/Home/Home';
import { GalleryPage } from './pages/GalleryPage/GalleryPage';
import ProtectedRoute from './routes/ProtectedRoute';
import 'swiper/css/bundle';
import { useEffect, useState } from 'react';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import { NotFound } from './pages/NotFound';
import axios from 'axios';
import Analytics from './analytics';
import AnalyticsPage from './pages/AnalyticsPage';
Analytics.initializeGA()

function App() {
  const [showInfoIcon, setshowInfoIcon] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Send page view event on route change
    const title = location.pathname.split('/')[1] ? location.pathname.split('/')[1] : 'landing'
    Analytics.sendEvent('custom_page_view', title, location.pathname)
  }, [location]);

  const updateNavbar = (d) => {
    setshowInfoIcon(d);
  };

  // Add a request interceptor
  axios.interceptors.request.use(function (config) {
    config.headers.authorization = 'Bearer ' + localStorage.getItem('token')
    return config;
  });

  return (
    <div className='App'>
      <ToastContainer />
        <ScrollToTop />
        <Navbar showInfoIcon={showInfoIcon} />
        <div className='meta'>
          <Switch>
            <ProtectedRoute
              exact
              path='/dashboard'
              component={Dashboard}
              updateNavbar={updateNavbar}
            />
            <ProtectedRoute
              exact
              path='/account'
              component={Account}
            />
            <ProtectedRoute
              exact
              path='/analytics'
              component={AnalyticsPage}
            />
            <Route
              exact
              path='/'
              render={(props) => (
                <Home {...props} updateNavbar={updateNavbar} />
              )}
            />
            <Route
              exact
              path='/faq'
              render={(props) => (
                <Faq {...props} updateNavbar={updateNavbar} />
              )} />

              <Route
              exact
              path='/privacy'
              render={(props) => (
                <Privacy {...props} updateNavbar={updateNavbar} />
              )} />

            <Route
              exact
              path='/terms'
              render={(props) => (
                <Terms {...props} updateNavbar={updateNavbar} />
              )} />

            <Route
              exact
              path='/login'
              render={(props) => (
                <Login {...props} updateNavbar={updateNavbar} />
              )} />
            <Route
              exact
              path='/forgot-password'
              component={SendResetPasswordLink}
            />
            <Route
              exact
              path='/update/:token'
              component={UpdatePassword}
            />
            <Route
              exact
              path='/signup/:userName'
              component={Signup}
            />
            <Route
              exact
              path='/signup'
              component={Signup}
            />
            <Route
              exact
              path='/invalid/:username'
              render={(props) => (
                <NotFound {...props} updateNavbar={updateNavbar} />
              )} />
            <Route
              exact
              path='/:username'
              render={(props) => (
                <GalleryPage {...props} updateNavbar={updateNavbar} />
              )}
            />
          </Switch>
        </div>
        <GlobalFooter />
    </div>
  );
}

export default App;
