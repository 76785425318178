import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GallaryCard } from "../../components/Gallary/GallaryCard";
import Loading from "../../components/Loading/Loading";
import { Error } from "../../components/Messages/messages";
import "./GalleryPage.css";
import { isAuthenticated } from "../../components/Auth/auth";

import { CopyToClipboard } from "react-copy-to-clipboard";
import UAParser from "ua-parser-js";
import { init, onClickLink } from "../../utility";
import { ReactComponent as Threads } from "../../assets/threads.svg";

export const GalleryPage = (props) => {
  const userUsername = props.match.params.username;
  const [data, setData] = useState([]);
  const [imagesOpen, setImagesOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [setText] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const parser = new UAParser();
    const result = parser.getResult();

    if (result.browser.name.toLowerCase().includes("tiktok")) {
      document.body.classList.add("in-app-browser");
    }
  }, []);

  useEffect(() => {
    document.title = `Tapfolio | @${userUsername}`;
    getAllPictures();
    props.updateNavbar(true);
    document.body.classList.add("gallery-page");

    return () => {
      document.body.classList.remove("gallery-page");
    };
  }, []);

  const onCopyUsername = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const showImagesDrawer = () => {
    setImagesOpen(true);
  };

  const onImagesDrawerClose = () => {
    setImagesOpen(false);
  };

  const getAllPictures = async () => {
    setLoading(true);
    await axios
      .get(`/api/pictures/username/${userUsername}`)
      .then(async (res) => {
        setLoading(false);
        if (res.status === 200) {
          setData(res.data);
        } else {
          Error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        setLoading(false);
        props.history.push(`/invalid/${userUsername}`);
      });
  };

  // Custom Analytics
  useEffect(() => {
    async function start() {
      const pastSessionId = localStorage.getItem("AnalyticsSessionId");
      const initPayload = {
        username: userUsername,
        referrer: document.referrer,
        pastSessionId: pastSessionId || "",
      };
      const session = await init(initPayload);
      localStorage.setItem(
        "AnalyticsSessionId",
        session && session.data && session.data._id
      );
    }
    start();
  }, []);

  return (
    <div className="GalleryPage">
      <div className="three-dots">
        <Drawer className="user-drawer" title="Contact Info" placement="bottom">
          <div
            dangerouslySetInnerHTML={{
              __html: data?.user?.description,
            }}
          ></div>
        </Drawer>

        <div>
          <div>
            {/* //Hidden input */}
            <input
              type="text"
              className="hidden-input"
              value={`https://tapfol.io/${userUsername}`}
              placeholder="Type some text here"
              onChange={(event) => setText(event.target.value)}
            />

            <Link to="/" className="info-button">
              <div className="copy-area">
                <img
                  className="copy-logo"
                  src={
                    "https://res.cloudinary.com/dhbf9ny7k/image/upload/v1682475548/logo-diff_duo0ed.png"
                  }
                  alt="Tapfolio Logo"
                  width="35"
                />
              </div>
            </Link>
          </div>
          <div>
            <a
              onClick={showImagesDrawer}
              className="fs-4"
              style={{ zIndex: "1000" }}
            >
              <MenuOutlined />
            </a>

            {/* Drawer */}
            <Drawer
              className="user-drawer"
              placement="bottom"
              onClose={onImagesDrawerClose}
              open={imagesOpen}
              onCopyUsername={onCopyUsername}
              style={{fontFamily: `${data?.themeFont}`}}
            >
              <CopyToClipboard
                text={`https://tapfol.io/${userUsername}`}
                onCopy={onCopyUsername}
                className="copy-area-in-modal"
              >
                <div style={{fontFamily: `${data?.themeFont}`}}>
                  @{userUsername}
                  <span className={`copy-feedback ${isCopied ? "active" : ""}`}>
                    {isCopied ? "URL Copied!" : ""}
                  </span>
                </div>
              </CopyToClipboard>

              {data?.pictures?.length > 0 &&
                data.pictures.map((d, index) => {
                  return (
                    <div className="mb-1" key={`GallaryPage_${d._id}`}>
                      <a
                        className={`${
                          slideIndex === index && "activeLink"
                        }`}
                        onClick={() => {
                          setSlideIndex(index);
                          onImagesDrawerClose();
                        }}
                      >
                        {d.title}
                      </a>
                    </div>
                  );
                })}

              {/* Login + Dashboard Button */}
              {isAuthenticated() ? (
                <Link className="try-tapfolio" to="/dashboard">
                  <UserOutlined style={{ fontSize: "22px" }} />
                  Dashboard
                </Link>
              ) : (
                <div className="try-tapfolio">
                  <Link to="/">Join Tapfolio</Link>
                  <Link to="/login">Login</Link>
                  {/* <Link to='/privacy'>Privacy</Link> */}
                </div>
              )}
            </Drawer>
          </div>
        </div>
      </div>

      <div>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <GallaryCard data={data} slideIndex={slideIndex} />
            
            <div className="logos">
              {data?.user?.social?.tiktok ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={data?.user?.social?.tiktok}
                  onClick={() =>
                    onClickLink(
                      data?.user?.social?.tiktok,
                      "CLICK_SOCIAL_TIKTOK",
                      data && data.pictures && data.pictures[slideIndex].picture?.url
                    )
                  }
                >
                  <i className="fa-brands fa-tiktok"></i>
                </a>
              ) : (
                ""
              )}

              {data?.user?.social?.linkedin ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={data?.user?.social?.linkedin}
                  onClick={() =>
                    onClickLink(
                      data?.user?.social?.linkedin,
                      "CLICK_SOCIAL_LINKEDIN",
                      data && data.pictures && data.pictures[slideIndex].picture?.url
                    )
                  }
                >
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              ) : (
                ""
              )}

              {data?.user?.social?.instagram ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={data?.user?.social?.instagram}
                  onClick={() =>
                    onClickLink(
                      data?.user?.social?.instagram,
                      "CLICK_SOCIAL_INSTAGRAM",
                      data && data.pictures && data.pictures[slideIndex].picture?.url
                    )
                  }
                >
                  <i className="fa-brands fa-instagram"></i>
                </a>
              ) : (
                ""
              )}

              {data?.user?.social?.threads ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={data?.user?.social?.threads}
                  onClick={() =>
                    onClickLink(
                      data?.user?.social?.threads,
                      "CLICK_SOCIAL_THREADS",
                      data && data.pictures && data.pictures[slideIndex].picture?.url
                    )
                  }
                >
                  <Threads className="threads" />
                </a>
              ) : (
                ""
              )}

              {data?.user?.social?.twitter ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={data?.user?.social?.twitter}
                  onClick={() =>
                    onClickLink(
                      data?.user?.social?.twitter,
                      "CLICK_SOCIAL_TWITTER",
                      data && data.pictures && data.pictures[slideIndex].picture?.url
                    )
                  }
                >
                  <i className="fa-brands fa-x-twitter"></i>
                </a>
              ) : (
                ""
              )}

              {data?.user?.social?.youtube ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={data?.user?.social?.youtube}
                  onClick={() =>
                    onClickLink(
                      data?.user?.social?.youtube,
                      "CLICK_SOCIAL_YOUTUBE",
                      data && data.pictures && data.pictures[slideIndex].picture?.url
                    )
                  }
                >
                  <i className="fa-brands fa-youtube"></i>
                </a>
              ) : (
                ""
              )}

              {data?.user?.social?.tipUrl ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className=""
                  href={data?.user?.social?.tipUrl}
                  onClick={() =>
                    onClickLink(
                      data?.user?.social?.tipUrl,
                      "CLICK_SOCIAL_TIP",
                      data && data.pictures && data.pictures[slideIndex].picture?.url
                    )
                  }
                >
                  <i className="fas fa-dollar-sign"></i>
                </a>
              ) : (
                ""
              )}

              {data?.user?.social?.email ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`mailto:${data?.user?.social?.email}`}
                  onClick={() =>
                    onClickLink(
                      data?.user?.social?.email,
                      "CLICK_SOCIAL_EMAIL",
                      data && data.pictures && data.pictures[slideIndex].picture?.url
                    )
                  }
                >
                  <i className="fas fa-envelope"></i>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
