import { Modal, Switch } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../Loading/Loading';
import { Error, Success } from '../../Messages/messages';
import imageCompression from 'browser-image-compression';
import { isAuthenticated } from '../../../components/Auth/auth';

const UploadForm = (props) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false);
  const [compressedFile, setCompressedFile] = useState();
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cover, setCover] = useState(false);

  const [order, setOrder] = useState();
  const [pictures, setPictures] = useState([]);

  const showModal = () => {
    setError('')
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData();

    // check file type
    const fileType = compressedFile.name.split('.').pop();
    if (
      fileType.toLowerCase() !== 'jpg' &&
      fileType.toLowerCase() !== 'png' &&
      fileType.toLowerCase() !== 'gif' &&
      fileType.toLowerCase() !== 'jpeg' &&
      fileType.toLowerCase() !== 'heic'
    ) {
      setLoading(false);
      Error(
        'File type not supported. Only jpg, png ,jpeg,heic and gifs are allowed.'
      );
      return;
    }

    formData.append('title', title);
    formData.append('text', description);
    formData.append('link', url);
    formData.append('file', compressedFile);
    formData.append('cover', cover);
    setError('')
    await axios
      .post('/api/pictures/upload', formData, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          Success(res.data.successMessage);
          setDescription('');
          setCompressedFile(null)
          setUrl('')
          setTitle('');
          handleCancel();
          document.location.reload();
        } else {
          Error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        setError(err?.response?.data?.errorMessage || 'You can not upload more pictures in this package.');
      });
  };

  const getAllUserPictures = async () => {
    await axios
      .get(`/api/pictures/user/${isAuthenticated()._id}`)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          console.info('User Pictures', res?.data?.pictures.length);
          setPictures(res?.data?.pictures);
          Success(res.data.successMessage);
        } else {
          Error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        setLoading(false);
        Error(err?.response?.data?.errorMessage);
      });
  };

  const checkQuantity = async () => {
    await axios
      .get(`/api/orders/${isAuthenticated().email}`)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setOrder(res?.data);
          Success(res.data.successMessage);
        } else {
          Error(res.data.errorMessage);
        }
      })
      .catch((err) => {
        setLoading(false);
        Error(err?.response?.data?.errorMessage);
      });
  };

  useEffect(() => {
    getAllUserPictures();
    checkQuantity();
  }, []);

  function handleImageUpload(event) {
    var imageFile = event.target.files[0];
    var controller = new AbortController();
    var options = {
      // other options here
      signal: controller.signal,
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
    };
    imageCompression(imageFile, options)
      .then(function (compressedFile) {
        setError('')
        return setCompressedFile(compressedFile); // write your own logic
      })
      .catch(function (error) {
        Error(error.message);
        setLoading(false);
        console.error(error); // output: I just want to stop
      });

    // simulate abort the compression after 1.5 seconds
    // setTimeout(function () {
    //     controller.abort(new Error('Taking a little longer...'));
    // }, 1500);
  }

  return (
    <div className='upload-form'>
      {loading ? (
        <Loading />
      ) : (
        <>
          {pictures.length >= parseInt(order?.qty) ? (
            <p className='maxxed-out'>
              You can not upload any more tap slides in this package. Please edit or delete existing tap slides or <Link to='/account'>upgrade</Link>.
            </p>
          ) : (
            <button
              className={`btn add-btn btn-primary ${pictures.length === 0 ? 'empty-array' : ''}`}
              onClick={showModal}
            >
              {pictures.length === 0 ? (
                <span>Add first link</span>
              ) : (
                <i className='fa-solid fa-plus'></i>
              )}
            </button>
          )}
        </>
      )}


      <Modal
        footer={false}
        open={isModalOpen}
        destroyOnClose
        onCancel={handleCancel}
      >
        <form onSubmit={submitHandler}>
          <h4 className='text-center'>New Tap</h4>
          <div className='form-floating mb-3'>
            <input
              type='text'
              name='title'
              required
              maxLength={32}
              className='form-control'
              id='floatingInput'
              placeholder='Image Title'
              onChange={(e) => setTitle(e.target.value)}
            />
            <label for='floatingInput'>
              Title (32 character limit)
            </label>
          </div>
          <div className='my-3'>
            <input
              className='form-control'
              name='file'
              required
              type='file'
              id='formFile'
              onChange={handleImageUpload}
              accept='.jpg,.png,.jpeg,.heic'
            />            
          </div>
          <div className='form-floating mb-3'>
            <input
              type='url'
              value={url}
              maxLength={500}
              name='url'
              className='form-control'
              id='floatingInput'
              placeholder='Image url'
              onChange={(e) => setUrl(e.target.value)}
            />
            <label for='floatingInput'>URL</label>
          </div>
          <div className='form-floating mb-3'>
            <textarea
              type='text'
              value={description}
              maxLength={50}
              className='form-control upload-desc'
              style={{ minHeight: '130px' }}
              id='floatingInput'
              placeholder='Description'
              onChange={(e) => setDescription(e.target.value)}
            />
            <label for='floatingInput'>
              Description (50 character limit)
            </label>
            <p className='text-muted text-end'>{description.length} / 50</p>
          </div>
          <div className='mb-3 text-dark'>
            <span>Full Screen Image </span>
            <Switch onChange={(value) => setCover(value)} />
          </div>
          <p className='text-danger'
            dangerouslySetInnerHTML={{ __html: error }}
          />
          <div>
            {loading ? (
            <button
            type='submit'
            disabled={true}
            className='btn btn-primary w-100'
          >
            Adding...
          </button>
          ) : (
            <button
              type='submit'
              disabled={!compressedFile}
              className='btn btn-primary w-100'
            >
              Upload
            </button>
          )}

          </div>
        </form>
      </Modal>

    </div>
  );
};

export default UploadForm;
