import React from 'react';
import './PanelMenu.css';

const Panel = ({ show, children, onClose }) => {
  // Function to handle the click event on links
  const handleLinkClick = (e) => {
    // Check if the clicked element is a link (you can expand this to other elements if needed)
    if (e.target.tagName === 'A') {
      onClose();
    }
  };

  return (
    <div className={`panel ${show ? 'toggled' : ''}`} onClick={handleLinkClick}>
      {/* Render content passed from parent */}
      {children}
    </div>
  );
}

export default Panel;
