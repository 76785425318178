// import React, { useEffect, useState } from 'react';
// import './Auth.css';
import './Privacy.css';

export const Privacy = () => {
  return (
    <div className='privacy-page'>

      <h1>Welcome to Tapfolio's Privacy Policy!</h1>
      <p>We value your privacy and want you to understand how we collect, use, and protect your personal information when you use our website and application. This Privacy Policy applies to all visitors, users, and others who access our website and application ("you" or "users").</p>

      <h5>Information We Collect</h5>
      <p>At Tapfolio, we may collect personal information, usage information, device information, and cookies. When you use our website and application, we may collect your name, email address, and other information you provide when you create an account. We may also collect information about how you use our website and application, such as the pages you visit and the features you use. Additionally, we may collect information about the device you use to access our website and application, such as the type of device, operating system, and browser. Lastly, we use cookies to collect information about your use of our website and application. The cookies we use are only for Google Analytics and are completely anonymized.</p>

      <h5>How We Use Your Information</h5>
      <p>We use the information we collect to provide and improve our website and application, personalize your experience, respond to your inquiries, and communicate with you about our products, services, and promotions. We may also analyze usage trends and improve our marketing and advertising.</p>

      <h5>Information Sharing</h5>
      <p>We do not sell your personal information to third parties for their marketing purposes. However, we may also share your information with law enforcement or other third parties in response to legal process or when required by law.</p>

      <h5>Data Security</h5>
      <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, we cannot guarantee the security of your information, as no security measures are perfect or impenetrable.</p>

      <h5>Processing your Information</h5>
      <p>For the most part, we do not process your information in-house, but give it to third party processors for processing. For example, when Stripe takes your payment information, they are a third party processor. They process your payment and remit the funds to us. So in many instances, it will be necessary for us to transmit your information to a third party processor, as we do not have the capability to perform these functions. More detail on third party processing is detailed below.</p>

      <p>However, we may, from time to time, process your data internally. The legal basis for this processing is both your consent to the processing, and our need to conduct our legitimate business interests. Our purposes in processing this information, if we do, is to administer, maintain, and improve our website and offerings, to enter into contracts with you, to fulfill the terms of those contracts, to keep records of our transactions and interactions, to be able to provide you with goods and services, to comply with our legal obligations, to obtain professional advice, and to protect the rights and interests of our company, our customers (including you), and any third parties. We may process the following data:</p>

      <ul>
        <li>Data associated with your account, such as your name, address, email address and payment information</li>

        <li>Data about your usage of our website, such as your IP address, geographical information, and how long you accessed our website and what you viewed.</li>
        <li>Data related to your personal profile, such as your name, address, or profile picture.</li>
        <li>Data that you provide us in the course of using our services.</li>
        <li>Data that you post on our website, such as comments or responses to blogs.</li>
        <li>Data that you submit to us when you make an inquiry regarding our website or offerings.</li>
        <li>Data related to your transactions with us, including your purchase of our goods or services. This information may include contact details and payment information.</li>
        <li>Data that you provide to us when you subscribe to our emails or newsletters, including your email address and contact information.</li>
        <li>Data that you submit to us via correspondence, such as when you email us with questions.</li>
        <li>Any other data identified in this policy, for the purpose of complying with our legal obligations, or to protect the vital interests of you or any other natural person.</li>
      </ul>

      <h5>International Data</h5>
      <p>Our website is hosted by servers located in the U.S. Therefore, if you reside in the European Union, some of your data will be transferred internationally to those servers. Your consent to this Privacy Policy followed by your submission of such data represents your agreement to that transfer.</p>

      <h5>Data Retention</h5>
      <p>At Tapfolio, we understand the importance of protecting your personal data and ensuring its confidentiality. That's why we have a strict policy of deleting all your images and information once you close your account. We believe that once you've decided to part ways with us, your data should go with you.</p>
      <p>We want you to feel confident that your data is safe and secure with us, and we take all necessary measures to ensure that it is. We will not retain any of your personal data for any longer than is necessary for the legitimate business purposes of running our platform.</p>
      <p>If you ever decide to delete your account with us, all your personal data, including any images or information you've shared with us, will be permanently deleted from our servers. We will not retain any copies of your data, nor will we use it for any other purposes.</p>
      <p>Please note that while we can control the retention of your personal data on our servers, we cannot control the retention policies of any third parties to whom we've transmitted your data. If you wish to have any third parties delete your data, you will need to contact them directly. However, we will provide you with a list of all third parties to whom we've transmitted your data upon your request.</p>
      <p>At Tapfolio, we value your privacy and are committed to protecting your personal data. If you have any questions or concerns about our data retention policy, please don't hesitate to contact us.</p>

      <h5>California Privacy Rights Disclosures</h5>
      <p>Where provided for by law and subject to any applicable exceptions, California residents may have the right:</p>
      <ul>
        <li>To know the categories of Personal Information that Tapfolio has collected about you, the business purpose for collecting your Personal Information, and the categories of sources from which the Personal Information was collected;</li>
        <li>To access the specific pieces of Personal Information that Tapfolio has collected about you;</li>
        <li>To know whether Tapfolio has disclosed your Personal Information for business purposes, the categories of Personal Information so disclosed, and the categories of third parties to whom we have disclosed your Personal Information;</li>
        <li>To have Tapfolio, under certain circumstances, delete your Personal Information;</li>
        <li>To instruct businesses that sell Personal Information to stop doing so – Tapfolio, however, does not sell Personal Information; and</li>
        <li>To be free from discrimination related to the exercise of these rights.</li>
      </ul>
      <p>If you would like to exercise any or all of these rights, you may do so by contacting us, or emailing us at hello@tapfol.io. After we receive your request, we may request additional information from you to verify your identity. Your authorized agent may submit requests in the same manner, although we may require the agent to present signed written permission to act on your behalf, and you may also be required to independently verify your identity with us and confirm that you have provided the agent permission to submit the request.</p>

      <h5>Links to Other Websites</h5>
      <p>The Service contains links to other websites not operated or controlled by Tapfolio, including social media services ("Third Party Sites"). The information that you share with Third Party Sites will be governed by the specific privacy policies and terms of use of the Third Party Sites and not by this Privacy Policy. By providing these links we do not imply that we endorse or have reviewed these sites. Please contact the Third Party Sites directly for information on their privacy practices and policies.</p>

      {/* Removed since we dont do this anymore. RK */}
      {/* <h5>Processing Images</h5>
      <p>Tapfolio uses Google Cloud Vision API (safeSearchDetection) image processing. Google does not store the image. This allows users to make sure the image being uploaded is safe and free of any negativity. At Tapfolio, we value the trust of our users and take all necessary measures to ensure that their data is handled with care and in compliance with all applicable laws and regulations. <a href="https://cloud.google.com/vision/docs/data-usage">Learn more about Google Cloud Vision API data usage.</a></p> */}

      <h5>Storing Images</h5>
      <p>At Tapfolio, we use Cloudinary's cloud-based image management platform to store images uploaded by our users. This allows us to provide fast and reliable access to images from any device and enables us to optimize images for web performance. All images stored on Cloudinary will be protected by appropriate security measures, including encryption and access controls, to ensure that only authorized personnel have access to the data. We will only store images for as long as necessary to provide our services, and we will delete images upon request from the user.</p>

      <h5>Processing and Storing Data</h5>
      <p>At Tapfolio, we use MongoDB to store user data such as user profiles, preferences, and other related information. We use MongoDB because it is a scalable and reliable database solution that allows us to manage our user data efficiently. All data stored on MongoDB will be protected by appropriate security measures, including encryption and access controls, to ensure that only authorized personnel have access to the data. We will only store data for as long as necessary to provide our services, and we will delete data upon request from the user, subject to applicable legal requirements.</p>

      <h5>Children</h5>
      <p>This website is not designed for use by children under age 16, and we do not knowingly solicit personal data from anyone under age 16. If you are under age 16, do not access or use our website or related products or services. If you become aware that we have collected data of anyone under the age of 16, please contact us so that we may delete that data.</p>

      <h5>Changes to this Privacy Policy</h5>
      <p>We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We encourage you to review this policy periodically to stay informed about our privacy practices.</p>

      <h5>Contact Us</h5>
      <p>If you have any questions or concerns about this privacy policy or our privacy practices, please contact us at <a href="mailto:hello@tapfol.io">hello@tapfol.io</a>. Thank you for choosing Tapfolio!</p>

    </div>
  );
};

export default Privacy;
