import React from 'react';
import './Highlights.scss';

const Highlights = () => {
  return (
    <div className="highlights">
      <div className="screen">
        <img width="880" height="733" src="https://res.cloudinary.com/dhbf9ny7k/image/upload/v1701225507/x_efmdjo.png" alt="Main Visual" className="main-image"/>
      </div>
      <div className="right-side">
        <h2 className="menu-item">IMAGES</h2>
        <h2 className="menu-item">COLORS</h2>
        <h2 className="menu-item">FONTS</h2>
        <p>Curate your tapfolio to stand out from the rest of the crowd</p>
      </div>
    </div>
  );
};

export default Highlights;
